import React from 'react'

const TextInputLabel = ({
  name,
  label,
  placeholder,
  iconClassName,
  value,
  onChange,
  type,
  error,
}) => {
  return (
    <React.Fragment>
      <div class="form-group">
        <label>
          {iconClassName && <i class={iconClassName}></i>}
          {label}
        </label>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={error}
          onChange={onChange}
          value={value}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </React.Fragment>
  )
}

export default TextInputLabel
