import React from 'react'
import { Link } from 'react-router-dom'
import service8 from '../../../styletheme/images/service/service-8.png'
import service7 from '../../../styletheme/images/service/service-7.png'

const ServiceSemiCard = ({
  serviceHeaderId,
  serviceHeaderTitle,
  mainContent,
  serviceHeaderDescription,
  mediaUrl,
  recordCreatedDate,
  isPublic,
  onClick,
  path,
  ...rest
}) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 case-block">
      <div
        className="case-block-one wow fadeInUp"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <figure className="image-box">
            <Link
              to={path}
              onClick={onClick}
              className="lightbox-image"
              data-fancybox="gallery"
            >
              <img src={mediaUrl} alt="" />
            </Link>
          </figure>
          <div className="lower-content">
            <p>{serviceHeaderTitle}</p>
            <h4>
              <Link to={path} onClick={onClick}>
                {serviceHeaderDescription}
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceSemiCard
