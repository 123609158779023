import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

//<!-- StyleSheet -->

import '../src/styletheme/css/font-awesome-all.css'

import '../src/styletheme/css/flaticon.css'
import '../src/styletheme/css/owl.css'
import '../src/styletheme/css/bootstrap.css'
import '../src/styletheme/css/jquery.fancybox.min.css'
import '../src/styletheme/css/animate.css'
import '../src/styletheme/css/imagebg.css'
import '../src/styletheme/css/global.css'
import '../src/styletheme/css/header.css'
import '../src/styletheme/css/color.css'
import '../src/styletheme/css/style.css'
import '../src/styletheme/css/responsive.css'
import HomePage from './components/pages/homepage'
//import { BrowserRouter } from 'react-router-dom'
//import ScrollToTop from './components/reuseable/scrolltotop'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals   {/* <App />*/}
reportWebVitals()
