//import logo from './logo.svg';
//import './App.css';
import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

//internal libraries

//external library
import 'react-toastify/dist/ReactToastify.css'

//nav components
import NavBar from './components/reuseable/header/navigationBar'
import GeneralFooter from './components/reuseable/footer/generalfooter'

//pages
import HomePage from './components/pages/homepage'
import NotFound from './components/pages/error_pages/notfound'
import AboutUsPage from './components/pages/about/about'
import Projects from './components/pages/projects/projects'
import ProjectCaseStudy from './components/pages/projects/projectcasestudy'
import Services from './components/pages/services/services'
import ServiceDetail from './components/pages/services/servicedetail'
import Blogs from './components/pages/articles/blogs'
import BlogDetail from './components/pages/articles/blogdetail'
import RequestQuote from './components/pages/contactus/requestquote'

class App extends Component {
  componentDidMount() {
    // window.scrollTo(0, 0)
  }
  render() {
    return (
      <React.Fragment>
        <ToastContainer />

        <NavBar />
        <div className="content">
          <Switch>
            {/*<Route path="/tutorials" component={Tutorials}/>*/}

            <Route path="/" exact component={HomePage} />
            <Route path="/about" component={AboutUsPage} />
            <Route path="/projects" component={Projects} />
            <Route
              path="/projectcasestudy/:portfolioCaseTitle"
              component={ProjectCaseStudy}
            />
            <Route path="/services" component={Services} />
            <Route
              path="/servicedetail/:servicetitle"
              component={ServiceDetail}
            />
            <Route path="/blogs" component={Blogs} />
            <Route path="/blogdetail/:blogTitle" component={BlogDetail} />
            <Route path="/requestquote" component={RequestQuote} />
            <Route path="/notfound" component={NotFound} />
            <Redirect to="/notfound" />
          </Switch>
        </div>

        <GeneralFooter />
      </React.Fragment>
    )
  }
}

export default App
