import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

const MinimalBlogCard = ({
  blogId,
  blogPromoImageUrl,
  blogTitle,
  blogCategory,
  recordCreatedDate,
  path,
  ...rest
}) => {
  return (
    <div className="post">
      <div className="post-date">
        <span>
          <b>
            <Moment format="D MMM YYYY" withTitle>
              {recordCreatedDate != null
                ? recordCreatedDate
                : Date().toLocaleString()}
            </Moment>
          </b>
        </span>
      </div>
      <div className="file-box">
        <i className="far fa-folder-open"></i>
        <p>{blogCategory}</p>
      </div>
      <h5>
        <Link to={path}>{blogTitle}</Link>
      </h5>
    </div>
  )
}

export default MinimalBlogCard
