import React from 'react'
import Iframe from 'react-iframe'

const BlogSection = ({
  sectionMainContent,
  sectionMainImageUrl,
  sectionTitle,
  mediaUrls,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="text">
        <b>{/*mediaUrls[0].mediaType*/} </b>
        <h3>{sectionTitle}</h3>
        <p>{sectionMainContent}</p>
        <br />
      </div>
      {sectionMainImageUrl && (
        <React.Fragment>
          <img src={sectionMainImageUrl} alt="" />
          <br />
        </React.Fragment>
      )}
      {/*TODO add video option here  */}

      {mediaUrls && mediaUrls.length > 0 && mediaUrls.length < 2 && (
        <React.Fragment>
          <h4>Media Section </h4>
          {mediaUrls.map((value, index) => (
            <figure className="image-box" key={index}>
              {/*If only one image */}
              {value.mediaType === 'image' && (
                <React.Fragment>
                  <img src={value.mediaUrl} alt="" />
                  <p> {value.mediaCaption}</p>
                </React.Fragment>
              )}
              {value.mediaType === 'video' && (
                <React.Fragment>
                  <Iframe
                    url={value.mediaUrl}
                    width="100%"
                    height="465px"
                    id="tutorialVideo"
                    className="card-image"
                    display="initial"
                    frameborder="0"
                    allowfullscreen
                    position="relative"
                  />
                  <p> {value.mediaCaption}</p>
                </React.Fragment>
              )}
            </figure>
          ))}
        </React.Fragment>
      )}

      {mediaUrls && mediaUrls.length > 1 && (
        <React.Fragment>
          <h4>Media Section</h4>
          <figure className="image-box">
            {/*If only multiple image */}
            <div className="container">
              <div className="row imagetiles">
                {mediaUrls.map((value, index) => (
                  <div
                    className="col-lg-6 col-md-4 col-sm-4 col-xs-6"
                    key={index}
                  >
                    <br />
                    {value.mediaType === 'image' && (
                      <React.Fragment>
                        <img
                          src={value.mediaUrl}
                          className="img-responsive"
                          alt="media"
                        />
                        <p> {value.mediaCaption}</p>
                      </React.Fragment>
                    )}
                    {value.mediaType === 'video' && (
                      <React.Fragment>
                        <Iframe
                          url={value.mediaUrl}
                          width="100%"
                          height="100%"
                          id="blogVideo"
                          className="card-image"
                          display="initial"
                          frameborder="0"
                          allowfullscreen
                          position="relative"
                        />
                        <p> {value.mediaCaption}</p>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </figure>
        </React.Fragment>
      )}

      <br />
    </React.Fragment>
  )
}

export default BlogSection
