import http from '../../general/httpService'
import { apiEndpoint } from '../../../constants/config.json'

//Need to add pagnination to this website
export function fetchAllBlogTags() {
  const fullApiEndpoint =
    apiEndpoint + '/articlemanagement/api/fetch_all_blog_categories_tags'
  return http.get(fullApiEndpoint)
}

export function fetchAllBlogCategories() {
  const fullApiEndpoint =
    apiEndpoint + '/articlemanagement/api/fetch_all_blog_categories'
  return http.get(fullApiEndpoint)
}
