import React from 'react'

const TextAreaInputLabel = ({
  name,
  label,
  placeholder,
  iconClassName,
  value,
  onChange,
  error,
}) => {
  return (
    <React.Fragment>
      <div class="form-group">
        <label>
          <i class={iconClassName}></i>
          {label}
        </label>
        <textarea
          name={name}
          placeholder={placeholder}
          required={error}
          onChange={onChange}
          value={value}
        ></textarea>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </React.Fragment>
  )
}

export default TextAreaInputLabel
