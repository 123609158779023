import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import shape46 from '../../../styletheme/images/shape/shape-46.png'
import about5 from '../../../styletheme/images/resource/about-5.jpg'
import about3 from '../../../styletheme/images/resource/about-3.jpg'
import about4 from '../../../styletheme/images/resource/about-4.jpg'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import shape50 from '../../../styletheme/images/shape/shape-50.png'
import shape51 from '../../../styletheme/images/shape/shape-51.png'
import shape52 from '../../../styletheme/images/shape/shape-52.png'
import shape53 from '../../../styletheme/images/shape/shape-53.png'
import mobileDevMedium from '../../../styletheme/images/custom/notfound/notfoundpage.png'

class NotFound extends Component {
  state = {}
  render() {
    return (
      <React.Fragment>
        {/* NEED TO FIGURE THIS OUT   <div className="preloader">
          <div id="handle-preloader" className="handle-preloader">
            <div className="animation-preloader">
              <div className="spinner"></div>
              <div className="txt-loading">
                <span data-text-preloader="N" className="letters-loading">
                  N
                </span>
                <span data-text-preloader="A" className="letters-loading">
                  A
                </span>
                <span data-text-preloader="X" className="letters-loading">
                  X
                </span>
                <span data-text-preloader="L" className="letters-loading">
                  L
                </span>
                <span data-text-preloader="Y" className="letters-loading">
                  Y
                </span>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <div
              className="pattern-layer"
              styles={{ backgroundImage: `url(${shape62})` }}
            ></div>
            <div className="auto-container">
              <div className="content-box">
                <h1>Not Found</h1>
                <ul className="bread-crumb clearfix">
                  <li>
                    <Link to="/">Home</Link>
                    {/*<Link to="/" component={HomePage}/>*/}
                  </li>
                  <li>
                    <span>\\</span>
                  </li>
                  <li>Not Found</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <section className="about-style-six center">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-9 col-md-12 col-sm-12 content-column">
                <div id="content_block_11">
                  <div className="content-box">
                    <div className="sec-title text-left">
                      <figure class="image-box">
                        <img src={mobileDevMedium} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 content-column">
                <div className="sec-title text-left">
                  <h2>Page is not found</h2>
                  <div className=" btn-box">
                    <Link to="/" class="btn-lg theme-btn style-two centred">
                      Back to Home<span>+</span>
                    </Link>
                  </div>
                  <p>
                    We're not being able to find the page you're looking for,
                    please go back to the HomePage
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default NotFound
