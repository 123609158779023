import React, { Component } from 'react'
import test from '../../styletheme/images/banner/banner-1-6.png'

//import '../../styletheme/css/custom.css'
import shape55 from '../../styletheme/images/shape/shape-55.png'
import shape56 from '../../styletheme/images/shape/shape-56.png'
import shape61 from '../../styletheme/images/shape/shape-61.png'
import shape2 from '../../styletheme/images/shape/shape-2.png'
import shape58 from '../../styletheme/images/shape/shape-58.png'
import shape3 from '../../styletheme/images/shape/shape-3.png'
import shape4 from '../../styletheme/images/shape/shape-4.png'
import shape7 from '../../styletheme/images/shape/shape-7.png'
import shape8 from '../../styletheme/images/shape/shape-8.png'
import illustration1 from '../../styletheme/images/resource/illastration-1.png'
import testimonial9 from '../../styletheme/images/resource/testimonial-9.png'
import testimonial10 from '../../styletheme/images/resource/testimonial-10.png'
import shape5 from '../../styletheme/images/shape/shape-5.png'
import shape63 from '../../styletheme/images/shape/shape-63.png'
import case1 from '../../styletheme/images/gallery/case-1.png'
import case2 from '../../styletheme/images/gallery/case-2.png'
import case3 from '../../styletheme/images/gallery/case-3.png'
import case4 from '../../styletheme/images/gallery/case-4.png'
import shape46 from '../../styletheme/images/shape/shape-46.png'
import about5 from '../../styletheme/images/resource/about-5.jpg'
import about3 from '../../styletheme/images/resource/about-3.jpg'
import about4 from '../../styletheme/images/resource/about-4.jpg'
import shape62 from '../../styletheme/images/shape/shape-62.png'
import shape31 from '../../styletheme/images/shape/shape-31.png'
import shape57 from '../../styletheme/images/shape/shape-57.png'
import shape6 from '../../styletheme/images/shape/shape-6.png'
import shape30 from '../../styletheme/images/shape/shape-30.png'
import shape17 from '../../styletheme/images/shape/shape-17.png'
import wheel2 from '../../styletheme/images/icons/wheel-2.png'
import wheel3 from '../../styletheme/images/icons/wheel-3.png'
import wheel4 from '../../styletheme/images/icons/wheel-4.png'
import wheel5 from '../../styletheme/images/icons/wheel-5.png'
import banner1dash1 from '../../styletheme/images/banner/banner-1-1.png'
import banner1dash2 from '../../styletheme/images/banner/banner-1-2.png'
import banner1dash4 from '../../styletheme/images/banner/banner-1-4.png'
import banner1dash5 from '../../styletheme/images/banner/banner-1-5.png'
import banner1dash6 from '../../styletheme/images/banner/banner-1-6.png'
import dollar1 from '../../styletheme/images/icons/dollar-1.png'
import dollar2 from '../../styletheme/images/icons/dollar-2.png'
import dollar3 from '../../styletheme/images/icons/dollar-3.png'
import dollar4 from '../../styletheme/images/icons/dollar-4.png'
import dollar5 from '../../styletheme/images/icons/dollar-5.png'
import arrow4 from '../../styletheme/images/icons/arrow-4.png'
import decor1 from '../../styletheme/images/icons/decor-1.png'
import decor2 from '../../styletheme/images/icons/decor-2.png'
import piechart1 from '../../styletheme/images/icons/pie-chart-1.png'
import banner1dash3 from '../../styletheme/images/banner/banner-1-3.png'

import iconbg1 from '../../styletheme/images/icons/icon-bg-1.png'
import iconbg2 from '../../styletheme/images/icons/icon-bg-2.png'
import news1 from '../../styletheme/images/news/news-1.jpg'
import news2 from '../../styletheme/images/news/news-2.jpg'
import news3 from '../../styletheme/images/news/news-3.jpg'

import { Link } from 'react-router-dom'

//importing the images
import mobileDev from '../../styletheme/images/custom/homepage/mobiledev-445x660.png'
import mobileDevMedium from '../../styletheme/images/custom/homepage/mobiledev-2340x1560.png'
import mobileDevLgBkg from '../../styletheme/images/custom/homepage/mobiledev.jpg'
import dataScience from '../../styletheme/images/custom/homepage/data-science-246x273.png'
import { fetchAllServicesOffered } from '../../services/servicesOffered/servicesoOfferedService'
import ServiceMiniCard from '../common/cards/serviceminicard'
import { fetchAllProjectCaseStudies } from '../../services/servicesOffered/servicesoOfferedService'
import CaseStudyListCard from '../common/cards/casestudylistcard'
import { fetchLatestBlogs } from '../../services/blogs/blogsService'
import BlogCard from '../common/listrows/blogcard'
class HomePage extends Component {
  state = {
    allServicesOffered: [
      {
        mediaUrl: '',
        recordCreatedDate: '',
        serviceHeaderDescription: '',
        serviceHeaderId: '',
        mainContent: '',
        serviceHeaderTitle: '',
        isPublic: true,
      },
    ],
    portfolioItems: [
      {
        _id: '',
        portfolioCaseId: '',
        portfolioCaseTitle: '',
        portfolioOverviewDescription: '',
        recordCreatedDate: '',
        serviceHeaderId: '',
        serviceHeaderTitle: '',
        isPublic: true,
        sectionDetails: [
          {
            sectionFeactureDescription: '',
            sectionMainMediaUrl: '',
            sectionName: '',
            _id: '',
            mediaGalleryUrls: [''],
          },
        ],
        servicesUsed: [
          {
            _id: '',
            serviceId: '',
            serviceName: '',
            servicePromoMediaUrl: '',
          },
        ],
        technologiesUsed: [
          {
            technologyImageUrl: '',
            technologyName: '',
            _id: '',
          },
        ],
      },
    ],
    latestBlogs: [
      {
        blogId: '',
        blogTitle: '',
        blogCategory: '',
        blogPromoImageUrl: '',
        numberOfComments: 0,
        blogOverview: '',
        recordCreatedDate: '',
      },
    ],
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values
    //get data and rename it to courses (the object name)
    //get all discussion by the module id
    const { data: allServicesOffered } = await fetchAllServicesOffered()
    //const result = Object.entries(discussions);
    console.log(allServicesOffered)
    //update the courses by setting the state
    this.setState({ allServicesOffered })

    //get latest 3 the portfolio pieces
    const { data: portfolioItems } = await fetchAllProjectCaseStudies()
    console.log('Portfolio Items')
    console.log(portfolioItems)
    this.setState({ portfolioItems })

    //get the other records
    const { data: latestBlogs } = await fetchLatestBlogs(3)
    console.log(latestBlogs)
    //update ui
    this.setState({ latestBlogs })
  }

  render() {
    const { allServicesOffered, portfolioItems, latestBlogs } = this.state
    return (
      <React.Fragment>
        {/* <!--Page Title-->*/}
        <section
          class="page-title text-center style-two"
          style={{
            position: 'relative',
            overflow: 'hidden',
            padding: '60px 0px 110px 0px',
            width: '100%',
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
          }}
        >
          <img className="pattern-layer" alt="" src={shape62} />
        </section>
        {/*<!--End Page Title-->*/}

        <section className="banner-section">
          <div className="pattern-layer">
            <img className="pattern-1" src={shape63} alt="" />
            <img className="pattern-2" src={shape55} alt="" />
          </div>
          <div className="icon-layer">
            <img alt="" className="icon icon-1 rotate-me" src={wheel2} />
            <img className="icon icon-2 rotate-me" alt="" src={wheel3} />
            <img className="icon icon-3 rotate-me" alt="" src={wheel4} />
            <img className="icon icon-4 rotate-me" alt="" src={wheel5} />
          </div>
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box">
                  <h1 style={{ color: 'teal' }}>
                    Don't worry About The Complexities of Innovation
                  </h1>
                  <p>
                    With Vengarre in your corner, we will turn
                    <br /> those complexities into measureable results.
                  </p>
                  <div className="btn-box">
                    <Link to="/about" className="theme-btn style-two">
                      About Us<span>+</span>
                    </Link>
                    <Link to="/projects" className="theme-btn style-one">
                      Our Projects<span>+</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="slider-image-1 clearfix">
                  {/* Banner start*/}

                  <figure className="image image-1 float-bob-y">
                    <img src={dataScience} alt="" />
                  </figure>

                  {/* Banner end*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- about-style-two -->*/}
        <section class="about-style-two">
          <div class="auto-container">
            <div class="row clearfix">
              <div class="col-lg-7 col-md-12 col-sm-12 image-column">
                <div id="image_block_01">
                  <div
                    class="image-box wow slideInLeft"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                  >
                    <figure class="image js-tilt">
                      <img src={mobileDevLgBkg} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-12 col-sm-12 content-column">
                <div id="content_block_03">
                  <div class="content-box">
                    <div class="sec-title style-two text-left">
                      <p>About Company</p>
                      <h2>Who Are We</h2>

                      <img className="decor" src={banner1dash1} src="Test" />
                    </div>
                    <div class="text">
                      <p>
                        Vengarre is a team of IT practitioners and professionals
                        with more than thirty years of combined experience in
                        mobile development, web development, IT research, data
                        science, business analytics, and technical writing and
                        editing. The mission of Vengarre is to improve the
                        decision-making capability of organizations in the
                        health sector by providing high-quality IT solutions and
                        technologies to managers and practitioners and advancing
                        knowledge through research and data-driven analytics.
                      </p>
                    </div>
                    <div class="lower-box">
                      <Link to="/about" className="theme-btn style-three">
                        More About Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- about-style-two end -->*/}
        <section className="service-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <p>Services Vengarre Offers</p>
              <h2>Services We provide Our Clients</h2>
              <img alt="" className="decor" src={decor1} />
            </div>
            {/*Show the services we offer end */}
            <div className="row clearfix">
              {allServicesOffered.map((value, index) => (
                <React.Fragment key={index}>
                  <ServiceMiniCard
                    serviceHeaderTitle={value.serviceHeaderTitle}
                    serviceHeaderId={value.serviceHeaderId}
                    mediaUrl={value.mediaUrl}
                    serviceHeaderDescription={value.serviceHeaderDescription}
                    path={`/servicedetail/${value.serviceHeaderTitle}`}
                  />
                </React.Fragment>
              ))}
            </div>

            {/*Show the services we offer end */}
          </div>
        </section>
        <section className="service-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <p>Our Projects</p>
              <h2>Sample of Our Latest Work</h2>
              <img alt="" className="decor" src={decor1} />
            </div>
            {/*  Projects start*/}
            <div class="items-container row clearfix">
              {portfolioItems.map((value, index) => (
                <React.Fragment key={index}>
                  <CaseStudyListCard
                    _id={value._id}
                    portfolioCaseId={value.portfolioCaseId}
                    portfolioCaseTitle={value.portfolioCaseTitle}
                    portfolioOverviewDescription={
                      value.portfolioOverviewDescription
                    }
                    recordCreatedDate={value.recordCreatedDate}
                    serviceHeaderId={value.serviceHeaderId}
                    serviceHeaderTitle={value.serviceHeaderTitle}
                    path={`/projectcasestudy/${value.portfolioCaseTitle}`}
                    sectionDetails={value.sectionDetails}
                    servicesUsed={value.servicesUsed}
                    technologiesUsed={value.technologiesUsed}
                  />
                </React.Fragment>
              ))}
            </div>
            {/*  Projects end*/}
          </div>
        </section>

        <section className="news-section">
          <div className="title-inner bg-color-1">
            <div className="pattern-layer">
              <img src={shape7} className="pattern-1" alt="" />
              <img src={shape8} className="pattern-2" alt="" />
            </div>
            <div className="auto-container">
              <div className="sec-title text-center">
                <p>Our Blogs</p>
                <h2>What we have been up to</h2>
                <img src={decor1} className="decor" alt="" />
              </div>
            </div>
          </div>
          <div className="lower-content">
            <div className="auto-container">
              <div className="inner-content">
                {/* row start */}
                <div className="row clearfix">
                  {latestBlogs.map((value, index) => (
                    <div key={index}>
                      <BlogCard
                        blogTitle={value.blogTitle}
                        blogCategory={value.blogCategory}
                        blogPromoImageUrl={value.blogPromoImageUrl}
                        blogOverview={value.blogOverview}
                        recordCreatedDate={value.recordCreatedDate}
                        path={`/blogdetail/${value.blogTitle}`}
                      />
                    </div>
                  ))}
                </div>

                {/* row end */}
              </div>
            </div>
          </div>
        </section>

        {/* </div>*/}
      </React.Fragment>
    )
  }
}

export default HomePage
