import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import gallery1 from '../../../styletheme/images/gallery/gallery-1.jpg'
import gallery2 from '../../../styletheme/images/gallery/gallery-2.jpg'
import illustration9 from '../../../styletheme/images/resource/illastration-9.png'
import icon8 from '../../../styletheme/images/icons/icon-8.png'
import icon9 from '../../../styletheme/images/icons/icon-9.png'
import { NavLink, Link, useHistory } from 'react-router-dom'
import {
  fetchPortfolioByTitle,
  fetchAllPortfolioExceptId,
} from '../../../services/servicesOffered/servicesoOfferedService'
import custom from '../../../styletheme/css/custom.css'
import PortfolioSection from '../../common/blogcomponents/portfoliosection'
import CaseStudyListCard from '../../common/cards/casestudylistcard'
import CaseStudyCardSamePage from '../../common/cards/casestudycardsamepage'

class ProjectCaseStudy extends Component {
  state = {
    portfolioItem: {
      _id: '',
      portfolioCaseId: '',
      portfolioCaseTitle: '',
      portfolioOverviewDescription: '',
      recordCreatedDate: '',
      serviceHeaderId: '',
      serviceHeaderTitle: '',
      isPublic: true,
      sectionDetails: [
        {
          sectionFeatureDescription: '',
          sectionFeactureDescription: '',
          sectionMainMediaUrl: '',
          sectionName: '',
          _id: '',
          mediaGalleryUrls: [
            {
              mediaCaption: '',
              mediaPostedDate: '',
              mediaType: '',
              mediaUrl: '',
            },
          ],
        },
      ],
      servicesUsed: [
        {
          _id: '',
          serviceId: '',
          serviceName: '',
          servicePromoMediaUrl: '',
        },
      ],
      technologiesUsed: [
        {
          technologyImageUrl: '',
          technologyName: '',
          _id: '',
        },
      ],
    },
    //other projects
    portfolioItems: [
      {
        _id: '',
        portfolioCaseId: '',
        portfolioCaseTitle: '',
        portfolioOverviewDescription: '',
        recordCreatedDate: '',
        serviceHeaderId: '',
        serviceHeaderTitle: '',
        isPublic: true,
        sectionDetails: [
          {
            sectionFeactureDescription: '',
            sectionMainMediaUrl: '',
            sectionName: '',
            _id: '',
            mediaGalleryUrls: [''],
          },
        ],
        servicesUsed: [
          {
            _id: '',
            serviceId: '',
            serviceName: '',
            servicePromoMediaUrl: '',
          },
        ],
        technologiesUsed: [
          {
            technologyImageUrl: '',
            technologyName: '',
            _id: '',
          },
        ],
      },
    ],
  }

  //() {}

  async updateUI(value) {
    //window.history.replaceState(value.portfolioCaseTitle)
    this.props.history.push(`/projectcasestudy/${value.portfolioCaseTitle}`)
    //alert(value.portfolioCaseTitle)
    //this.context.router.push(`/projectcasestudy/${value.portfolioCaseTitle}`)
    // e.preventDefault()
    // Update our state here...
    //console.log('Need to Update Info')
    //const portfolioTitle = this.props.match.params.portfolioCaseTitle
    //get the portfolio pieces
    //const { data: portfolioItem } = await fetchPortfolioByTitle(portfolioTitle)
    const { data: portfolioItem } = await fetchPortfolioByTitle(
      value.portfolioCaseTitle,
    )
    console.log('Updated Portfolio Items')
    console.log(portfolioItem)
    this.setState({ portfolioItem })

    const portId = value._id
    console.log(portId)

    //fetch all projects so the client cant look at other projects
    const { data: portfolioItems } = await fetchAllPortfolioExceptId(portId) //except the current one
    this.setState({ portfolioItems })
  }

  componentDidUpdate() {
    console.log('Website Page Updated')
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    
    console.log(this.props.match.params.portfolioCaseTitle)
    const portfolioTitle = this.props.match.params.portfolioCaseTitle
    //get the portfolio pieces
    const { data: portfolioItem } = await fetchPortfolioByTitle(portfolioTitle)
    console.log('Portfolio Items')
    console.log(portfolioItem)
    this.setState({ portfolioItem })

    const portId = portfolioItem._id
    console.log(portId)

    //fetch all projects so the client cant look at other projects
    const { data: portfolioItems } = await fetchAllPortfolioExceptId(portId) //except the current one
    this.setState({ portfolioItems })
  }

  render() {
    const { portfolioItem, portfolioItems } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img className="pattern-layer" alt="" src={shape62} />
            <div className="auto-container">
              <div className="content-box">
                <figure className={custom.customHeaderRoundImg}>
                  {portfolioItem.sectionDetails.length > 0 && (
                    <img
                      alt=""
                      //className={custom.customHeaderRoundImg}
                      style={{
                        maxWidth: '150px',
                        maxHeight: '2500px',
                        borderRadius: '50%',
                      }}
                      //styles={{ height: '150px', width: '150px' }}
                      src={portfolioItem.sectionDetails[0].sectionMainMediaUrl}
                    />
                  )}
                  {portfolioItem.sectionDetails.length < 1 && (
                    <img src={icon9} alt="" />
                  )}
                </figure>
                <br />
                <h1>
                  {portfolioItem.portfolioCaseTitle} Portfolio Case Study{' '}
                </h1>
                <ul className="bread-crumb clearfix">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>\</span>
                  </li>
                  <li>Projects</li>
                  <li>
                    <span>\</span>
                  </li>
                  <li>Project Case Study</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <section className="project-details">
          <div className="upper-box">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                  <div className="project-details-content">
                    <div className="data-box">
                      <div className="title-box">
                        <span>
                          {portfolioItem.servicesUsed.map((value, index) => (
                            <span key={index}>
                              {' '}
                              {value.serviceName}
                              {index <
                                portfolioItem.servicesUsed.length - 1 && (
                                <span>, </span>
                              )}
                            </span>
                          ))}
                        </span>
                        <h3>{portfolioItem.portfolioCaseTitle}</h3>
                        <p>{portfolioItem.portfolioOverviewDescription}</p>
                      </div>
                      <div className="result-box">
                        <div className="inner">
                          {portfolioItem.sectionDetails.map(
                            (sectionValue, index) => (
                              <PortfolioSection
                                sectionTitle={sectionValue.sectionName}
                                sectionMainImageUrl={
                                  sectionValue.sectionMainMediaUrl
                                }
                                key={index}
                                sectionMainContent={
                                  sectionValue.sectionFeactureDescription != null? sectionValue.sectionFeactureDescription:sectionValue.sectionFeatureDescription
                                }
                                mediaUrls={sectionValue.mediaGalleryUrls}
                              />
                            ),
                          )}
                        </div>
                        {/*Show All projects except the  current one*/}
                        <div className="recent-case">
                          <h3>Other case studies</h3>

                          <div class="items-container row clearfix">
                            {/*  Projects start*/}

                            {portfolioItems.map((value, index) => (
                              <React.Fragment key={index}>
                                <CaseStudyCardSamePage
                                  _id={value._id}
                                  portfolioCaseId={value.portfolioCaseId}
                                  portfolioCaseTitle={value.portfolioCaseTitle}
                                  portfolioOverviewDescription={
                                    value.portfolioOverviewDescription
                                  }
                                  recordCreatedDate={value.recordCreatedDate}
                                  serviceHeaderId={value.serviceHeaderId}
                                  serviceHeaderTitle={value.serviceHeaderTitle}
                                  path={`/projectcasestudy/${value.portfolioCaseTitle}`}
                                  sectionDetails={value.sectionDetails}
                                  servicesUsed={value.servicesUsed}
                                  technologiesUsed={value.technologiesUsed}
                                  //onClick={this.updateUI()}
                                  onClick={() => this.updateUI(value)}
                                />
                              </React.Fragment>
                            ))}
                            {/*  Projects end*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                  <div className="project-sidebar">
                    <div className="info-box">
                      <div className="single-item">
                        <div className="box">
                          <div className="icon-box">
                            <i className="far fa-user"></i>
                          </div>
                          <span>Services Used</span>
                        </div>
                        <div className="text">
                          <p>
                            {portfolioItem.servicesUsed.map((value, index) => (
                              <p key={index}>
                                {' '}
                                {value.serviceName}
                                {index <
                                  portfolioItem.servicesUsed.length - 1 && (
                                  <p>, </p>
                                )}
                              </p>
                            ))}
                          </p>
                        </div>
                      </div>
                      <div className="single-item">
                        <div className="box">
                          <div className="icon-box">
                            <i className="far fa-sun"></i>
                          </div>
                          <span>Technologies</span>
                        </div>
                        <div className="text">
                          {portfolioItem.technologiesUsed.map(
                            (value, index) => (
                              <p key={index}>
                                {' '}
                                {value.technologyName}
                                {index <
                                  portfolioItem.technologiesUsed.length - 1 && (
                                  <span>, </span>
                                )}
                              </p>
                            ),
                          )}
                        </div>
                      </div>
                      <div className="single-item">
                        <div className="box">
                          <div className="icon-box">
                            <i className="far fa-calendar-alt"></i>
                          </div>
                          <span>Interested</span>
                        </div>
                        <div className="text">
                          <p>
                            {' '}
                            <NavLink
                              className="theme-btn style-one"
                              to="/requestquote"
                              style={{ padding: '8px' }}
                            >
                              Request Quote
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default ProjectCaseStudy
