import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import news2 from '../../../styletheme/images/news/news-2.jpg'
import news1 from '../../../styletheme/images/news/news-1.jpg'
import news7 from '../../../styletheme/images/news/news-1.jpg'
import news8 from '../../../styletheme/images/news/news-8.jpg'
import news9 from '../../../styletheme/images/news/news-9.jpg'
import news10 from '../../../styletheme/images/news/news-10.jpg'
import news11 from '../../../styletheme/images/news/news-11.jpg'
import instagram1 from '../../../styletheme/images/resource/instagram-1.jpg'
import instagram3 from '../../../styletheme/images/resource/instagram-3.jpg'
import instagram2 from '../../../styletheme/images/resource/instagram-2.jpg'
import instagram4 from '../../../styletheme/images/resource/instagram-4.jpg'
import instagram5 from '../../../styletheme/images/resource/instagram-5.jpg'
import {
  fetchAllBlogs,
  fetchLatestBlogs,
} from '../../../services/blogs/blogsService'
import BlogCard from '../../common/listrows/blogcard'

import SideBarComponent from '../../common/sidebar/sidebarcomponent'
import {
  fetchAllBlogTags,
  fetchAllBlogCategories,
} from '../../../services/blogs/categories/categoryService'
//TODO: Need to implment the side bar light weight network calls to populate it
class Blogs extends Component {
  state = {
    //add model here
    allblogs: [
      {
        blogId: '',
        blogCategory: '',
        blogOverview: '',
        blogPromoImageUrl: '',
        blogTitle: '',
        recordCreatedDate: '',
        isDraft: true,
        numberOfComments: 0,
        authorDetail: [
          {
            authorProfileImageUrl: '',
            firstName: '',
            lastName: '',
          },
        ],
        blogReferences: [
          {
            referenceAuthors: '',
            referenceBody: '',
            referenceTitle: '',
          },
        ],
        blogSections: [
          {
            sectionMainContent: '',
            sectionMainImageUrl: '',
            sectionTitle: '',
            mediaUrls: [
              {
                mediaCaption: '',
                mediaPostedDate: '',
                mediaType: '',
                mediaUrl: '',
              },
            ],
          },
        ],
        blogTags: [
          {
            tagId: '',
            tagName: '',
          },
        ],
      },
    ],
    //fetch latest light weight obj

    latestBlogs: [
      {
        blogId: '',
        blogTitle: '',
        blogCategory: '',
        blogPromoImageUrl: '',
        numberOfComments: 0,
        blogOverview: '',
        recordCreatedDate: '',
      },
    ],
    blogTags: [
      {
        tagId: '',
        tagName: '',
        isDeleted: false,
        recordCreatedDate: '',
      },
    ],
    blogTagCategories: [
      {
        tagId: '',
        tagName: '',
        isDeleted: false,
        recordCreatedDate: '',
      },
    ],
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values
    //get data and rename it to courses (the object name)
    //get all discussion by the module id
    const { data: allblogs } = await fetchAllBlogs()
    //const result = Object.entries(discussions);
    console.log(allblogs)
    //update the courses by setting the state
    this.setState({ allblogs })
    //get the other records
    const { data: latestBlogs } = await fetchLatestBlogs(3)
    console.log(latestBlogs)
    //update ui
    this.setState({ latestBlogs })

    //fetch the blog tags and categories

    const { data: blogTags } = await fetchAllBlogTags()
    this.setState({ blogTags })

    const { data: blogTagCategories } = await fetchAllBlogCategories()
    this.setState({ blogTagCategories })
  }

  render() {
    const { allblogs, latestBlogs, blogTags, blogTagCategories } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img alt="" className="pattern-layer" src={shape62} />
            <div className="auto-container">
              <div className="content-box">
                <h1>Vengarre Blogs</h1>
              </div>
            </div>
          </section>
        </div>

        {/*<!-- blog-classic-sidebar -->*/}
        <section className="sidebar-page-container blog-classic-sidebar">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                <div className="blog-classic-content">
                  {/* row start */}
                  <div className="row clearfix">
                    {allblogs.map((value, index) => (
                      <div key={index}>
                        <BlogCard
                          blogTitle={value.blogTitle}
                          blogCategory={value.blogCategory}
                          blogPromoImageUrl={value.blogPromoImageUrl}
                          blogOverview={value.blogOverview}
                          recordCreatedDate={value.recordCreatedDate}
                          path={`/blogdetail/${value.blogTitle}`}
                        />
                      </div>
                    ))}
                  </div>

                  {/* row end */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                {/* Add the side bar Here start*/}
                <SideBarComponent
                  latestBlogs={latestBlogs}
                  blogTags={blogTags}
                  blogTagCategories={blogTagCategories}
                />
                {/* Add the side bar Here end*/}
              </div>
            </div>
          </div>
        </section>
        {/*<!-- blog-classic-sidebar end -->*/}

        {/*<!--Scroll to top-->*/}
        <button className="scroll-top scroll-to-target" data-target="html">
          <span className="fas fa-angle-up"></span>
        </button>
      </React.Fragment>
    )
  }
}

export default Blogs
