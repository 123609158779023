import React from 'react'
import { Link } from 'react-router-dom'
import news6 from '../../../styletheme/images/news/news-6.jpg'
import Moment from 'react-moment'
import arrow1 from '../../../styletheme/images/icons/arrow-1.png'
import border3 from '../../../styletheme/images/icons/border-3.png'

const OurProcess = ({
  processDescription,
  processIcon,
  processSequenceNum,
  processTitle,
  count,
  ...rest
}) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 work-block">
      <div className="work-block-two">
        <div className="inner-box">
          {processSequenceNum < count && (
            <img src={arrow1} className="arrow-box" alt="" />
          )}

          <div className="icon-box">
            <img src={border3} className="border-line" alt="" />
            <i className={processIcon}></i>
          </div>
          <div
            className="count-box wow zoomIn animated"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <span>0{processSequenceNum}</span>
          </div>
          <div
            className="content-box wow slideInUp"
            data-wow-delay="00ms"
            data-wow-duration="1500ms"
          >
            <h4>
              <Link to="#">{processTitle}</Link>
            </h4>
            <p>{processDescription}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProcess
