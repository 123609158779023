import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import { fetchAllProjectCaseStudies } from '../../../services/servicesOffered/servicesoOfferedService'
import CaseStudyListCard from '../../common/cards/casestudylistcard'

class Projects extends Component {
  state = {
    portfolioItems: [
      {
        _id: '',
        portfolioCaseId: '',
        portfolioCaseTitle: '',
        portfolioOverviewDescription: '',
        recordCreatedDate: '',
        serviceHeaderId: '',
        serviceHeaderTitle: '',
        isPublic: true,
        sectionDetails: [
          {
            sectionFeactureDescription: '',
            sectionMainMediaUrl: '',
            sectionName: '',
            _id: '',
            mediaGalleryUrls: [''],
          },
        ],
        servicesUsed: [
          {
            _id: '',
            serviceId: '',
            serviceName: '',
            servicePromoMediaUrl: '',
          },
        ],
        technologiesUsed: [
          {
            technologyImageUrl: '',
            technologyName: '',
            _id: '',
          },
        ],
      },
    ],
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    //get all the portfolio pieces
    const { data: portfolioItems } = await fetchAllProjectCaseStudies()
    console.log('Portfolio Items')
    console.log(portfolioItems)
    this.setState({ portfolioItems })
  }

  render() {
    const { portfolioItems } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img alt="" className="pattern-layer" src={shape62} />
            <div className="auto-container">
              <div className="content-box">
                <h1>Project Case Studies</h1>
              </div>
            </div>
          </section>
        </div>
        <section className="about-style-six">
          <div className="auto-container">
            {/*  Projects start*/}
            <div class="items-container row clearfix">
              {portfolioItems.map((value, index) => (
                <React.Fragment key={index}>
                  <CaseStudyListCard
                    _id={value._id}
                    portfolioCaseId={value.portfolioCaseId}
                    portfolioCaseTitle={value.portfolioCaseTitle}
                    portfolioOverviewDescription={
                      value.portfolioOverviewDescription
                    }
                    recordCreatedDate={value.recordCreatedDate}
                    serviceHeaderId={value.serviceHeaderId}
                    serviceHeaderTitle={value.serviceHeaderTitle}
                    path={`/projectcasestudy/${value.portfolioCaseTitle}`}
                    sectionDetails={value.sectionDetails}
                    servicesUsed={value.servicesUsed}
                    technologiesUsed={value.technologiesUsed}
                  />
                </React.Fragment>
              ))}
            </div>
            {/*  Projects end*/}
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Projects
