//

import http from '../../general/httpService'
import { apiEndpoint } from '../../../constants/config.json'

export function postNewsLetterSubscriber(requestDetail) {
  const fullApiEndpoint =
    apiEndpoint + '/articlemanagement/api/create_new_newsletter_subscriber'
  //need to write to firebase then the server
  return http.post(fullApiEndpoint, requestDetail)
}
