import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import {
  fetchServiceOfferedByTitle,
  fetchPortfolioPiecesByServiceId,
  fetchAllOtherServicesExceptId,
} from '../../../services/servicesOffered/servicesoOfferedService'
import { Link } from 'react-router-dom'
import shape48 from '../../../styletheme/images/shape/shape-48.png'
import decor1 from '../../../styletheme/images/icons/decor-1.png'
import illustration6 from '../../../styletheme/images/resource/illastration-6.png'
import servicedetail2 from '../../../styletheme/images/service/service-details-2.jpg'
import CaseStudyListCard from '../../common/cards/casestudylistcard'
import notfound from '../../../styletheme/images/custom/notfound.png'
import PortfolioSection from '../../common/blogcomponents/portfoliosection'
import { toast } from 'react-toastify'
import Joi from 'joi-browser'
import TextInputLabel from '../../reuseable/form/textinputlabel'
import TextAreaInputLabel from '../../reuseable/form/textareainputlabel'
import SubmitButton from '../../reuseable/buttons/submitbutton'
import ServiceSemiCard from '../../common/cards/servicesemicard'
import { postProjectRequest } from '../../../services/contact/projectRequest'
import projectRequestImg from '../../../styletheme/images/custom/contact.png'

class ServiceDetail extends Component {
  state = {
    serviceOffered: {
      _id: '',
      mediaUrl: '',
      recordCreatedDate: '',
      serviceHeaderDescription: '',
      serviceHeaderId: '',
      mainContent: '',
      serviceHeaderTitle: '',
      isPublic: true,
      technologiesUsed: [
        {
          technologyImageUrl: '',
          technologyName: '',
          _id: '',
        },
      ],
      sectionDetails: [
        {
          sectionFeactureDescription: '',
          sectionMainMediaUrl: '',
          sectionName: '',
          _id: '',
          mediaGalleryUrls: [''],
        },
      ],
    },

    portfolioItems: [
      {
        _id: '',
        portfolioCaseId: '',
        portfolioCaseTitle: '',
        portfolioOverviewDescription: '',
        recordCreatedDate: '',
        serviceHeaderId: '',
        serviceHeaderTitle: '',
        isPublic: true,
        sectionDetails: [
          {
            sectionFeactureDescription: '',
            sectionMainMediaUrl: '',
            sectionName: '',
            _id: '',
            mediaGalleryUrls: [''],
          },
        ],
        servicesUsed: [
          {
            _id: '',
            serviceId: '',
            serviceName: '',
            servicePromoMediaUrl: '',
          },
        ],
        technologiesUsed: [
          {
            technologyImageUrl: '',
            technologyName: '',
            _id: '',
          },
        ],
      },
    ],
    otherServices: [
      {
        _id: '',
        mediaUrl: '',
        recordCreatedDate: '',
        serviceHeaderDescription: '',
        serviceHeaderId: '',
        mainContent: '',
        serviceHeaderTitle: '',
        isPublic: true,
        technologiesUsed: [
          {
            technologyImageUrl: '',
            technologyName: '',
            _id: '',
          },
        ],
        sectionDetails: [
          {
            sectionFeactureDescription: '',
            sectionMainMediaUrl: '',
            sectionName: '',
            _id: '',
            mediaGalleryUrls: [''],
          },
        ],
      },
    ],
    data: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      subject: '',
      messageDetail: '',
    },
    errors: {},
  }
  async updateUI(value) {
    //window.history.replaceState(value.portfolioCaseTitle)
    this.props.history.push(`/servicedetail/${value.serviceHeaderTitle}`)

    const serviceTitle = value.serviceHeaderTitle
    const { data: serviceOffered } = await fetchServiceOfferedByTitle(
      serviceTitle,
    )

    const serviceId = serviceOffered._id

    //update the UI
    this.setState({ serviceOffered })

    //get the portfolio pieces
    const { data: portfolioItems } = await fetchPortfolioPiecesByServiceId(
      serviceId,
    )

    this.setState({ portfolioItems })

    const { data: otherServices } = await fetchAllOtherServicesExceptId(
      serviceId,
    )

    this.setState({ otherServices })
  }

  componentDidUpdate() {
    console.log('Website Page Updated')
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    console.log(this.props.match.params.servicetitle)
    const { data: serviceOffered } = await fetchServiceOfferedByTitle(
      this.props.match.params.servicetitle,
    )

    console.log(serviceOffered)
    const serviceId = serviceOffered._id

    //update the UI
    this.setState({ serviceOffered })

    //get the portfolio pieces
    const { data: portfolioItems } = await fetchPortfolioPiecesByServiceId(
      serviceId,
    )
    console.log('Portfolio Items')
    console.log(portfolioItems)
    this.setState({ portfolioItems })

    //fetch other services minus this current one
    console.log('Other Services')
    const { data: otherServices } = await fetchAllOtherServicesExceptId(
      serviceId,
    )
    console.log(otherServices)
    this.setState({ otherServices })
  }
  //helper
  truncate(str, length) {
    return str.length > 10 ? str.substring(0, length) + '...' : str
  }

  //Contact Us Form Section
  //Joi validation schema
  schema = {
    emailAddress: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ['com', 'edu', 'net'] },
      })
      .required()
      .label('Email Address'), //use joi in node as well
    firstName: Joi.string().required().label('First Name'),
    lastName: Joi.string().required().label('Last Name'),
    subject: Joi.string().required().label('Subject'),
    messageDetail: Joi.string().required().label('Message'),
  }
  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null

    const errors = {}

    for (let item of error.details) {
      errors[item.path[0]] = item.message
      toast.error('An unexpected error occurred.\n' + item.message)
    }
    return errors
  }
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema)

    return error ? error.details[0].message : null
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = this.validate()
    console.log(errors)

    this.setState({ errors: errors || {} })
    if (errors) return

    this.doSubmit(e)
  }

  handleChange = ({ currentTarget: input }) => {
    //input validation
    const errors = { ...this.state.errors }

    const errorMessage = this.validateProperty(input)

    if (errorMessage) errors[input.name] = errorMessage
    else delete errors[input.name]
    //data seccount (model)
    const data = { ...this.state.data }

    data[input.name] = input.value
    this.setState({ data, errors })
  }

  doSubmit = async (e) => {
    //login function
    try {
      const { data } = this.state
      const contactPayLoad = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        subject: data.subject,
        messageDetail: data.messageDetail,
      }
      console.log('Data to send to the server')
      console.log(contactPayLoad)
      //Send to server
      await postProjectRequest(contactPayLoad)

      //get the current location
      const { state } = this.props.location

      //force a reload of the page
      // window.location = state ? state.from.pathname : '/dashboard'
      toast.success(
        'Your Request has been successfully sent. Thank you, we will get back to you soon.',
      )
      //Successful so clear the form
      e.target.reset()
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.emailAddress = ex.response.data
        toast.error('An unexpected error occurred.\n' + ex.response.data)
        //Update the UI
        this.setState({ errors })
      }
    }
  }

  render() {
    const {
      serviceOffered,
      portfolioItems,
      otherServices,
      data,
      errors,
    } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img className="pattern-layer" alt="" src={shape62} />

            <div className="auto-container">
              <div className="content-box">
                <br />
                <h1>{this.props.match.params.servicetitle} Solutions </h1>
                <ul className="bread-crumb clearfix">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>\</span>
                  </li>
                  <li>Services</li>
                  <li>
                    <span>\</span>
                  </li>
                  <li>Service Detail</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        {/*<!-- service-details -->*/}
        <section className="service-details">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                <div className="service-details-content">
                  <div className="inner-box">
                    <div className="two-column">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 left-column">
                          <div className="title-box">
                            <h2>{serviceOffered.serviceHeaderTitle}</h2>
                            <Link to="/requestquote" className="btn-style-four">
                              Start Your Project<span>+</span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 right-column">
                          <div className="text">
                            <p>{serviceOffered.serviceHeaderDescription}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure className="single-image">
                      <img
                        src={serviceOffered.mediaUrl}
                        alt=""
                        style={{ maxHeight: '500px', maxWidth: '100%' }}
                      />
                    </figure>
                    <div className="carousel-box">
                      <div className="carousel-inner">
                        <div className="two-column-carousel-2 owl-carousel owl-theme owl-nav-none">
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-analysis"></i>
                              </div>
                              <span>Service</span>
                              <h4>Operational Anaytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Explain to you how all this mistaken idea of
                                denouncing pleasure.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-google-analytics"></i>
                              </div>
                              <span>Service</span>
                              <h4>Stream Analytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Take a trivials example, which of us every
                                undertakes some advantages.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-analysis"></i>
                              </div>
                              <span>Service</span>
                              <h4>Operational Anaytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Explain to you how all this mistaken idea of
                                denouncing pleasure.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-google-analytics"></i>
                              </div>
                              <span>Service</span>
                              <h4>Stream Analytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Take a trivials example, which of us every
                                undertakes some advantages.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-analysis"></i>
                              </div>
                              <span>Service</span>
                              <h4>Operational Anaytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Explain to you how all this mistaken idea of
                                denouncing pleasure.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                          <div className="single-item">
                            <div className="box">
                              <div className="icon-box">
                                <i className="flaticon-google-analytics"></i>
                              </div>
                              <span>Service</span>
                              <h4>Stream Analytics</h4>
                            </div>
                            <div className="inner">
                              <p>
                                Take a trivials example, which of us every
                                undertakes some advantages.
                              </p>
                              <a
                                href="service-details.html"
                                className="btn-style-four"
                              >
                                More Details<span>+</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="technology-box">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                          <div className="content-box">
                            <h3>Technologies that we use</h3>
                            <p>
                              We use the following technologies and techniques
                              for our {serviceOffered.serviceHeaderTitle}{' '}
                              service.
                            </p>
                            <ul className="list-item clearfix">
                              {serviceOffered.technologiesUsed.map(
                                (value, index) => (
                                  <li key={index}>
                                    <span className="dote"></span>
                                    {value.technologyName}
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        </div>
                        {/*
                        TODO
                        */}
                        <div className="col-lg-6 col-md-6 col-sm-12 icon-column">
                          <div className="icon-box">
                            {serviceOffered.technologiesUsed.length > 0 &&
                              serviceOffered.technologiesUsed.map(
                                (value, index) => (
                                  <div className={'icon icon-' + (index + 1)}>
                                    <i className="flaticon-ui"></i>
                                    <br />
                                    <h4>{value.technologyName}</h4>
                                  </div>
                                ),
                              )}
                            {serviceOffered.technologiesUsed.length < 1 && (
                              <div className="content-box ">
                                <img
                                  src={notfound}
                                  alt="Not Found"
                                  style={{ maxHeight: '50%', maxWidth: '50%' }}
                                />
                                <h5>No Technologies and Techniques found.</h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Add Sections here*/}
                    <div className="result-box">
                      <div className="inner">
                        {serviceOffered.sectionDetails.map(
                          (sectionValue, index) => (
                            <PortfolioSection
                              sectionTitle={sectionValue.sectionName}
                              sectionMainImageUrl={
                                sectionValue.sectionMainMediaUrl
                              }
                              sectionMainContent={
                                sectionValue.sectionFeactureDescription
                              }
                              mediaUrls={sectionValue.mediaGalleryUrls}
                            />
                          ),
                        )}
                      </div>
                    </div>
                    <div className="recent-case">
                      {portfolioItems.length > 0 && (
                        <React.Fragment>
                          <h3>Recent case studies</h3>

                          <div class="items-container row clearfix">
                            {/*  Projects start*/}

                            {portfolioItems.map((value, index) => (
                              <React.Fragment key={index}>
                                <CaseStudyListCard
                                  _id={value._id}
                                  portfolioCaseId={value.portfolioCaseId}
                                  portfolioCaseTitle={value.portfolioCaseTitle}
                                  portfolioOverviewDescription={
                                    value.portfolioOverviewDescription
                                  }
                                  recordCreatedDate={value.recordCreatedDate}
                                  serviceHeaderId={value.serviceHeaderId}
                                  serviceHeaderTitle={value.serviceHeaderTitle}
                                  path={`/projectcasestudy/${value.portfolioCaseTitle}`}
                                  sectionDetails={value.sectionDetails}
                                  servicesUsed={value.servicesUsed}
                                  technologiesUsed={value.technologiesUsed}
                                />
                              </React.Fragment>
                            ))}
                            {/*  Projects end*/}
                          </div>
                        </React.Fragment>
                      )}
                    </div>

                    <div className="application-box">
                      <br /> <br />
                      <h3>Other Services</h3>
                      <p>Check out the other services that we offer.</p>
                      <div className="inner clearfix">
                        <div className="row clearfix">
                          {/* Other services except the current one*/}
                          {otherServices.map((value, index) => (
                            <React.Fragment key={index}>
                              <ServiceSemiCard
                                serviceHeaderId={value._id}
                                serviceHeaderDescription={this.truncate(
                                  value.serviceHeaderDescription,
                                  80,
                                )}
                                mediaUrl={value.mediaUrl}
                                recordCreatedDate={value.recordCreatedDate}
                                serviceHeaderTitle={value.serviceHeaderTitle}
                                path={`/servicedetail/${value.serviceHeaderTitle}`}
                                onClick={() => this.updateUI(value)}
                              />
                            </React.Fragment>
                          ))}
                          {/* Other services except the current end*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <React.Fragment>
          {/*  <!-- info-section -->*/}
          <section
            className="info-section alternate-2"
            style={{ padding: '0px 0px 130px 0px !Important' }}
          >
            <div className="auto-container">
              <div className="inner-container">
                <div className="top-inner mb-55">
                  <div className="sec-title text-center">
                    <p>Contact Information</p>
                    <h2>We would like to hear from you.</h2>

                    <img alt="" className="decor" src={decor1} />
                  </div>
                  <div className="text text-center">
                    <p>
                      If you have any questions or just want to reach out to us,
                      please fill out the form below.
                      <br />
                      We will get back to you as soon as possible.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<!-- info-section end -->*/}

          {/*<!-- contact-section -->*/}
          <section className="contact-section alternate-2">
            <img alt="" className="pattern-layer" src={shape48} />
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div id="content_block_09">
                    <div className="content-box">
                      <form
                        onSubmit={this.handleSubmit}
                        id="contact-form"
                        className="default-form"
                      >
                        <TextInputLabel
                          name="firstName"
                          value={this.firstName}
                          label="First Name"
                          labelClass="far fa-user"
                          type="text"
                          placeholder="Enter first name here"
                          error={errors[this.firstName]}
                          onChange={this.handleChange}
                          iconClassName="far fa-user"
                        />

                        <TextInputLabel
                          name="lastName"
                          value={this.lastName}
                          label="Last Name"
                          labelClass="far fa-user"
                          type="text"
                          placeholder="Enter last name here"
                          error={errors[this.lastName]}
                          onChange={this.handleChange}
                          iconClassName="far fa-user"
                        />

                        <TextInputLabel
                          name="emailAddress"
                          value={this.emailAddress}
                          label="Email Address"
                          labelClass="far fa-envelope"
                          type="email"
                          placeholder="Enter email address here"
                          error={errors[this.emailAddress]}
                          onChange={this.handleChange}
                          iconClassName="far fa-envelope"
                        />

                        <TextInputLabel
                          name="subject"
                          value={this.subject}
                          label="Subject"
                          labelClass="far fa-user"
                          type="text"
                          placeholder="Enter subject here"
                          error={errors[this.subject]}
                          onChange={this.handleChange}
                          iconClassName="far fa-user"
                        />

                        <TextAreaInputLabel
                          name="messageDetail"
                          value={this.messageDetail}
                          label="Message"
                          labelClass="fas fa-text-height"
                          placeholder="Enter your message goes here"
                          error={errors[this.messageDetail]}
                          onChange={this.handleChange}
                          iconClassName="fas fa-text-height"
                        />

                        <SubmitButton
                          className="theme-btn style-one"
                          type="submit"
                          name="submit-form"
                          label="Send Your Message"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                  <div
                    className="image-box wow slideInRight"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                  >
                    <figure className="image js-tilt">
                      <img alt="" src={projectRequestImg} />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<!-- contact-section end -->*/}
        </React.Fragment>
      </React.Fragment>
    )
  }
}

export default ServiceDetail
