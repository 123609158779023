import React from 'react'
import { Link } from 'react-router-dom'
import service8 from '../../../styletheme/images/service/service-8.png'
import service7 from '../../../styletheme/images/service/service-7.png'
import custom from '../../../styletheme/css/custom.css'
import gallery1 from '../../../styletheme/images/gallery/gallery-1.jpg'
import icon1 from '../../../styletheme/images/icons/icon-1.png'
import gallery2 from '../../../styletheme/images/gallery/gallery-2.jpg'
import icon2 from '../../../styletheme/images/icons/icon-2.png'

const CaseStudyListCard = ({
  _id,
  portfolioCaseId,
  portfolioCaseTitle,
  portfolioOverviewDescription,
  recordCreatedDate,
  serviceHeaderId,
  serviceHeaderTitle,
  sectionDetails,
  technologiesUsed,
  servicesUsed,
  path,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all finance banking entertainment marketing healthcare">
        <div className="case-block-two">
          <div className="inner-box">
            <figure className="image-box">
              {sectionDetails && sectionDetails.length > 0 && (
                <React.Fragment>
                  {sectionDetails[0].sectionMainMediaUrl && sectionDetails[0].mediaGalleryUrls != null &&
                <img src={sectionDetails[0].sectionMainMediaUrl} alt="" />
              }
                 {!sectionDetails[0].sectionMainMediaUrl && sectionDetails[0].mediaGalleryUrls &&
                  <img src={sectionDetails[0].mediaGalleryUrls[0].mediaUrl} alt="" />
                } 
                </React.Fragment>
              )}
              {!sectionDetails && sectionDetails.length < 1 && (
                <img src={gallery1} alt="" />
              )}
              <div className="client-box">
                {technologiesUsed && technologiesUsed.length > 0 && (
                  <span>{technologiesUsed[0].technologyName}</span>
                )}
                <div>
                  {technologiesUsed && technologiesUsed.length > 0 && (
                    <img
                      className={'client-logo'}
                      src={technologiesUsed[0].technologyImageUrl}
                      alt=""
                      styles={{
                        maxHeight: '72px',
                        maxWidth: '72px',
                        padding: '0px !Important',
                      }}
                    />
                  )}
                  {!technologiesUsed && technologiesUsed.length < 1 && (
                    <img
                      className="client-logo"
                      src={icon1}
                      alt=""
                      styles={{
                        maxHeight: '72px',
                        maxWidth: '72px',
                        padding: '0px !Important',
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="link">
                <Link to={path}>
                  <i className="flaticon-hyperlink"></i>
                </Link>
              </div>
              <div className="overlay-layer"></div>
            </figure>
            <div className="lower-content">
              <div className="box">
                <div className="icon-box">
                  <i className="flaticon-billboard"></i>
                </div>
                {servicesUsed && servicesUsed.length > 0 && (
                  <p>{servicesUsed[0].serviceName}</p>
                )}
                <h4>
                  <Link to={path}>{portfolioCaseTitle}</Link>
                </h4>
              </div>
              <div className="text">
                <p>{portfolioOverviewDescription}</p>
              </div>
              <div className="link">
                <Link to={path} className="btn-style-four">
                  Read More<span>+</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CaseStudyListCard
