import React, { Component } from 'react'
import shape46 from '../../../styletheme/images/shape/shape-46.png'
import shape49 from '../../../styletheme/images/shape/shape-49.png'

import shape62 from '../../../styletheme/images/shape/shape-62.png'
import decor1 from '../../../styletheme/images/icons/decor-1.png'
import service7 from '../../../styletheme/images/service/service-7.png'
import service8 from '../../../styletheme/images/service/service-8.png'
import service9 from '../../../styletheme/images/service/service-9.png'
import service10 from '../../../styletheme/images/service/service-10.png'
import line3 from '../../../styletheme/images/icons/line-3.png'

import Moment from 'react-moment'
import { fetchAboutUsHeader } from '../../../services/about/aboutUsService'

import { fetchAllServicesOffered } from '../../../services/servicesOffered/servicesoOfferedService'
import ServiceFullCard from '../../common/cards/servicefullcard'
import OurProcess from '../../common/processflow/ourprocess'

class AboutUsPage extends Component {
  state = {
    about: {
      _id: '',
      aboutDetail: '',
      aboutId: '',
      aboutPromoImageUrl1: '',
      aboutPromoImageUrl2: '',
      aboutPromoImageUrl3: '',
      aboutPromoVideoUrl: '',
      aboutTitle: '',
      aboutWorkProcesses: [
        {
          processDescription: '',
          processIcon: '',
          processSequenceNum: 0,
          processTitle: '',
        },
      ],
      recordCreatedDate: '',
      yearBusinessStarted: 0,
    },
    allServices: [
      {
        mediaUrl: '',
        recordCreatedDate: '',
        serviceHeaderDescription: '',
        serviceHeaderId: '',
        mainContent: '',
        serviceHeaderTitle: '',
        isPublic: true,
      },
    ],
  }

  getYear() {
    return new Date().getFullYear()
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values

    const { data: about } = await fetchAboutUsHeader()
    //const result = Object.entries(discussions);
    console.log('About Us')
    console.log(about)
    //update the courses by setting the state
    this.setState({ about })

    //fetch all services offered
    const { data: allServices } = await fetchAllServicesOffered()

    console.log('All Services')
    console.log(allServices)

    this.setState({ allServices })
  }

  render() {
    const { about, allServices } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img className="pattern-layer" src={shape62} alt="" />
            <div className="auto-container">
              <div className="content-box">
                <h1>About Vengarre Consulting</h1>
              </div>
            </div>
          </section>
        </div>
        <section className="about-style-six">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div id="image_block_05">
                  <div className="image-box">
                    <figure className="image image-1">
                      <img src={about.aboutPromoImageUrl1} alt="" />
                    </figure>
                    <figure className="image image-2">
                      <img src={about.aboutPromoImageUrl2} alt="" />
                    </figure>
                    <figure className="image image-3">
                      <img src={about.aboutPromoImageUrl3} alt="" />
                    </figure>
                    <div className="elipse"></div>

                    <img className="patter-layer" src={shape46} alt="" />
                    <div className="text">
                      <span>
                        <h5>
                          Since
                          <br />
                        </h5>
                        {about.yearBusinessStarted}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_11">
                  <div className="content-box">
                    <div className="sec-title text-left">
                      <p>About Company</p>
                      <h2>{about.aboutTitle}</h2>
                      <img className="decor" src={shape46} alt="" />
                    </div>
                    <div className="text">
                      <p>{about.aboutDetail}</p>
                    </div>
                    <div className="video-btn">
                      <a
                        href="https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s"
                        className="lightbox-image"
                        data-caption=""
                      >
                        <i className="flaticon-play-button"></i>
                      </a>
                      <h5>
                        Video Intro
                        <br />
                        About Our Company
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-section-five">
          <div className="auto-container">
            <div className="sec-title text-center mb-60">
              <p>Services Vengarre Offers</p>
              <h2>
                We provide intelligent solutions to
                <br />
                your problems
              </h2>
              <div
                className="decor"
                styles={{
                  backgroundImage: `url(${decor1})`,
                }}
              ></div>
            </div>
            <div className="row clearfix">
              {allServices.map((value, index) => (
                <React.Fragment key={index}>
                  <ServiceFullCard
                    serviceHeaderTitle={value.serviceHeaderTitle}
                    serviceHeaderId={value.serviceHeaderId}
                    mediaUrl={value.mediaUrl}
                    serviceHeaderDescription={value.serviceHeaderDescription}
                    path={`/servicedetail/${value.serviceHeaderTitle}`}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>

        {/*<h3>
          Our Process here(gather requirements, draft solution document and
          sketches), get client approval, implementation and testing, sign off
          by client
          </h3>*/}

        {/*<!-- process-style-two -->*/}
        <section className="process-style-two alternate-2">
          <img className="pattern-layer" alt="" src={shape49} />
          <div className="auto-container">
            <div className="sec-title text-center style-two">
              <p>Our Work Process</p>
              <h2>Simple {'&'} clean work process</h2>
              <img className="decor" alt="" src={decor1} />
            </div>
            <div className="inner-content">
              <img className="line" src={line3} alt="" />

              <div className="row clearfix">
                {/*Process Start     */}

                {about.aboutWorkProcesses.map((value, index) => (
                  <React.Fragment key={index}>
                    <OurProcess
                      processDescription={value.processDescription}
                      processIcon={value.processIcon}
                      processSequenceNum={value.processSequenceNum}
                      processTitle={value.processTitle}
                      count={about.aboutWorkProcesses.length}
                    />
                  </React.Fragment>
                ))}
                {/*Process End     */}
              </div>
            </div>
          </div>
        </section>
        {/*<!-- process-style-two end -->*/}
      </React.Fragment>
    )
  }
}

export default AboutUsPage
