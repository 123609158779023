import React from 'react'

const SubscribeButton = ({
  name,
  type,
  buttonTitle,
  iconClassName,
  className,
}) => {
  return (
    <React.Fragment>
      <button type={type} name={name} className={className}>
        <i className={iconClassName}></i>
        {buttonTitle}
      </button>
    </React.Fragment>
  )
}

export default SubscribeButton
