import React, { useEffect, useState } from 'react'
import arrowUp from '../../styletheme/images/icons/arrow-up.png'

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false)

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <div className="scroll-to-top footer-nav " style={{ paddingLeft: '85%' }}>
      {isVisible && (
        <div onClick={scrollToTop} style={{ height: '100px', width: '100px' }}>
          <img src={arrowUp} alt="Go to top" />
        </div>
      )}
    </div>
  )
}
