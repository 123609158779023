import http from '../general/httpService'
import { apiEndpoint } from '../../constants/config.json'

//Need to add pagnination to this website
export function fetchAllBlogs() {
  const fullApiEndpoint = apiEndpoint + '/articlemanagement/api/fetch_all_blogs'
  return http.get(fullApiEndpoint)
}

//fetch_blog_by_title

export function fetchBlogByTitle(blogTitle) {
  const fullApiEndpoint =
    apiEndpoint + '/articlemanagement/api/fetch_blog_by_title/' + blogTitle
  return http.get(fullApiEndpoint)
}

//fetch latest blogs (light)

export function fetchLatestBlogs(limit) {
  const fullApiEndpoint =
    apiEndpoint + '/articlemanagement/api/fetch_latest_blogs/' + limit
  return http.get(fullApiEndpoint)
}
