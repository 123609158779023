import React from 'react'
import { Link } from 'react-router-dom'
import service8 from '../../../styletheme/images/service/service-8.png'
import service7 from '../../../styletheme/images/service/service-7.png'
import custom from '../../../styletheme/css/custom.css'

const ServiceFullCard = ({
  serviceHeaderId,
  serviceHeaderTitle,
  mainContent,
  serviceHeaderDescription,
  mediaUrl,
  recordCreatedDate,
  isPublic,
  path,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="col-lg-6 col-md-6 col-sm-12 block-column">
        <div
          className="service-block-five wow fadeInLeft"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <figure className={custom.serviceImageSize}>
              <img src={mediaUrl} alt="" styles={{}} />
            </figure>
            <br />
            <h3>
              <Link to={path}>{serviceHeaderTitle}</Link>
            </h3>
            <div>
              <p>{serviceHeaderDescription}</p>
            </div>
            <div className="link">
              <Link to={path} className="btn-style-four">
                Read More<span>+</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ServiceFullCard
