import React from 'react'

const SubmitButton = ({ name, type, label, className, validate }) => {
  return (
    <div class="form-group">
      <button
        class={className}
        type={type}
        name={name}
        disabled={validate}
        className={className}
      >
        {label}
      </button>
    </div>
  )
}
/*
<button disabled={validate} className={className}>
      {label}
    </button>

*/
export default SubmitButton
