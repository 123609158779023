import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import service7 from '../../../styletheme/images/service/service-7.png'
import service8 from '../../../styletheme/images/service/service-8.png'
import service9 from '../../../styletheme/images/service/service-9.png'
import service10 from '../../../styletheme/images/service/service-10.png'
import decor1 from '../../../styletheme/images/icons/decor-1.png'
import { fetchAllServicesOffered } from '../../../services/servicesOffered/servicesoOfferedService'
import ServiceMiniCard from '../../common/cards/serviceminicard'
class Services extends Component {
  state = {
    allServicesOffered: [
      {
        mediaUrl: '',
        recordCreatedDate: '',
        serviceHeaderDescription: '',
        serviceHeaderId: '',
        mainContent: '',
        serviceHeaderTitle: '',
        isPublic: true,
      },
    ],
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values
    //get data and rename it to courses (the object name)
    //get all discussion by the module id
    const { data: allServicesOffered } = await fetchAllServicesOffered()
    //const result = Object.entries(discussions);
    console.log(allServicesOffered)
    //update the courses by setting the state
    this.setState({ allServicesOffered })
  }
  render() {
    const { allServicesOffered } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section class="page-title text-center style-two">
            <img alt="" className="pattern-layer" src={shape62} />
            <div class="auto-container">
              <div class="content-box">
                <h1>Our Services</h1>
                <ul class="bread-crumb clearfix">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <span>\\</span>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="service-section-five">
            <div className="auto-container">
              <div className="sec-title text-center mb-60">
                <h3>Services Offered by Vengarre Consulting</h3>
                <p>More than Solutions</p>
                <h2>
                  Data sceince solutions for startup
                  <br />
                  and enterprises
                </h2>

                <img src={decor1} alt="" className="decor" />
              </div>
              {/*Show the services we offer end */}
              <div className="row clearfix">
                {allServicesOffered.map((value, index) => (
                  <React.Fragment key={index}>
                    <ServiceMiniCard
                      serviceHeaderTitle={value.serviceHeaderTitle}
                      serviceHeaderId={value.serviceHeaderId}
                      mediaUrl={value.mediaUrl}
                      serviceHeaderDescription={value.serviceHeaderDescription}
                      path={`/servicedetail/${value.serviceHeaderTitle}`}
                    />
                  </React.Fragment>
                ))}
              </div>

              {/*Show the services we offer end */}
            </div>
          </section>
        </div>
      </React.Fragment>
    )
  }
}

export default Services
