import http from '../general/httpService'
import { apiEndpoint } from '../../constants/config.json'

//Need to add pagnination to this website
export function fetchAllServicesOffered() {
  const fullApiEndpoint =
    apiEndpoint + '/servicesmanagement/api/get_all_services_offered'
  return http.get(fullApiEndpoint)
}

//get_service_offered_by_title

export function fetchServiceOfferedByTitle(serviceHeaderTitle) {
  const fullApiEndpoint =
    apiEndpoint +
    '/servicesmanagement/api/get_service_offered_by_title/' +
    serviceHeaderTitle
  return http.get(fullApiEndpoint)
}

export function fetchPortfolioPiecesByServiceId(serviceId) {
  const fullApiEndpoint =
    apiEndpoint +
    '/servicesmanagement/api/get_all_portfolio_by_service_id/' +
    serviceId
  return http.get(fullApiEndpoint)
}

//MARK: TODO need to create filter to filter projects by services
export function fetchAllProjectCaseStudies() {
  const fullApiEndpoint =
    apiEndpoint + '/servicesmanagement/api/get_all_portfolio'
  return http.get(fullApiEndpoint)
}

export function fetchPortfolioByTitle(portfolioCaseTitle) {
  const fullApiEndpoint =
    apiEndpoint +
    '/servicesmanagement/api/get_portfolio_by_title/' +
    portfolioCaseTitle
  return http.get(fullApiEndpoint)
}
//_id
export function fetchAllPortfolioExceptId(_id) {
  const fullApiEndpoint =
    apiEndpoint +
    '/servicesmanagement/api/get_all_portfolio_except_target/' +
    _id
  return http.get(fullApiEndpoint)
}

export function fetchAllOtherServicesExceptId(_id) {
  const fullApiEndpoint =
    apiEndpoint +
    '/servicesmanagement/api/get_all_other_services_except_target/' +
    _id
  return http.get(fullApiEndpoint)
}
