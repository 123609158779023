import React from 'react'
import { Link } from 'react-router-dom'
import service8 from '../../../styletheme/images/service/service-8.png'
import service7 from '../../../styletheme/images/service/service-7.png'

const ServiceMiniCard = ({
  serviceHeaderId,
  serviceHeaderTitle,
  mainContent,
  serviceHeaderDescription,
  mediaUrl,
  recordCreatedDate,
  isPublic,
  path,
  ...rest
}) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 block-column" {...rest}>
      <div
        className="service-block-five wow fadeInLeft"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="icon-box">
            <i className="flaticon-data"></i>
          </div>
          <h3>
            <Link to={path}>{serviceHeaderTitle}</Link>
          </h3>
          <ul className="list-item clearfix">
            {serviceHeaderDescription}
            {/*<li>
              <span className="dots"></span>NLP and Text Analysis
            </li>*/}
          </ul>

          <div className="link">
            <Link to={path} className="btn-style-four">
              Read More<span>+</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceMiniCard
