import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../../styletheme/logo/VengarreConsulting.png'
import ScrollToTop from '../../reuseable/scrolltotop'
import decor3 from '../../../styletheme/images/icons/decor-3.png'
import FooterSubscriberForm from '../../common/footer/footersubscribtionform'

const GeneralFooter = () => {
  return (
    <React.Fragment>
      {/*main-footer */}

      <ScrollToTop />

      <footer className="main-footer">
        <div className="footer-top">
          <div className="auto-container">
            <div className="widget-section">
              <div className="row clearfix">
                <div className="col-md-2 footer-column">
                  <figure className="logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt=""
                        style={{ height: '150px', width: '200px' }}
                      />
                    </Link>
                  </figure>
                </div>
                <div className="col-lg-3 col-md-7 col-sm-12 footer-column">
                  <div className="footer-widget contact-widget">
                    <div className="widget-title">
                      <h3>Contact Us</h3>
                      <img src={decor3} className="decor" alt="" />
                    </div>
                    <div className="widget-content">
                      <div className="box">
                        <h5>Office Location</h5>
                        <p>Bangor Maine, USA</p>
                      </div>
                      <div className="box">
                        <h5>Quick Contact</h5>
                        <ul className="clearfix">
                          {/*<li>
                            <i className="flaticon-music"></i>
                            <a href="tel:0055567890">+00 555 67 890</a>
                          </li>*/}
                          <li>
                            <i className="flaticon-gmail"></i>
                            <a href="mailto:vengarre@vengarre.com">
                              vengarre@vengarre.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                  <div className="footer-widget links-widget">
                    <div className="widget-title">
                      <h3>Useful Links</h3>
                      <img src={decor3} className="decor" alt="" />
                    </div>
                    <div className="widget-content">
                      <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 column">
                          <ul className="links clearfix">
                            <li>
                              <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                              <NavLink to="/about">About</NavLink>
                            </li>
                            <li>
                              <NavLink to="/projects">Projects</NavLink>
                            </li>
                            <li>
                              <NavLink to="/services">Services</NavLink>
                            </li>
                            <li>
                              <NavLink to="/blogs">Blogs</NavLink>
                            </li>
                            <li>
                              <NavLink to="/requestquote">
                                Request Quote
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10 col-sm-10 footer-column">
                  <div className="footer-widget about-widget">
                    <div className="widget-title">
                      <h3>About Company</h3>

                      <img src={decor3} className="decor" alt="" />
                    </div>
                    <div className="widget-content">
                      <div className="box">
                        <figure className="logo">
                          <a href="index.html">
                            <img
                              src={logo}
                              alt=""
                              style={{ height: '80px', width: '90px' }}
                            />
                          </a>
                        </figure>
                        <div className="text">
                          <p>
                            Don't worry about the Complexities of Innovation.
                            With Vengarre in your corner, we will turn those
                            complexities into measureable outcomes
                          </p>
                        </div>
                      </div>
                      {/*Footer subsription Form start */}
                      <FooterSubscriberForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-one">
          <div className="auto-container clearfix">
            <div className="copyright pull-left">
              <p>
                Copyright &copy;{new Date().getFullYear()}{' '}
                <Link to="/">Vengarre Consulting</Link>, All Rights Reserved.
              </p>
            </div>
            <ul className="footer-nav pull-right">
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms and Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/*main-footer end 
      <ScrollToTop>
        <button onClick={scrollToTop()} classNameName="scroll-top scroll-to-target" data-target="html">
          <span classNameName="fas fa-angle-up"></span>
        </button>
      </ScrollToTop> ScrollButton*/}
    </React.Fragment>
  )
}

export default GeneralFooter
