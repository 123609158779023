import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import {
  fetchBlogByTitle,
  fetchLatestBlogs,
} from '../../../services/blogs/blogsService'
import {
  fetchAllBlogTags,
  fetchAllBlogCategories,
} from '../../../services/blogs/categories/categoryService'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import MinimalBlogCard from '../../common/listrows/minimalblogcard'
import news24 from '../../../styletheme/images/news/news-24.jpg'
import BlogSection from '../../common/blogcomponents/blogsection'
import SideBarComponent from '../../common/sidebar/sidebarcomponent'

class BlogDetail extends Component {
  state = {
    //add model here
    blog: {
      blogId: '',
      blogCategory: '',
      blogOverview: '',
      blogPromoImageUrl: '',
      blogTitle: '',
      recordCreatedDate: '',
      isDraft: true,
      numberOfComments: 0,
      blogReferences: [
        {
          referenceAuthors: '',
          referenceBody: '',
          referenceTitle: '',
        },
      ],
      authorDetail: [
        {
          authorProfileImageUrl: '',
          firstName: '',
          lastName: '',
        },
      ],
      blogSections: [
        {
          sectionMainContent: '',
          sectionMainImageUrl: '',
          sectionTitle: '',
          mediaUrls: [
            {
              mediaCaption: '',
              mediaPostedDate: '',
              mediaType: '',
              mediaUrl: '',
            },
          ],
        },
      ],
      blogTags: [
        {
          tagId: '',
          tagName: '',
        },
      ],
    },
    latestBlogs: [
      {
        blogId: '',
        blogTitle: '',
        blogCategory: '',
        blogPromoImageUrl: '',
        numberOfComments: 0,
        blogOverview: '',
        recordCreatedDate: '',
      },
    ],
    blogTags: [
      {
        tagId: '',
        tagName: '',
        isDeleted: false,
        recordCreatedDate: '',
      },
    ],
    blogTagCategories: [
      {
        tagId: '',
        tagName: '',
        isDeleted: false,
        recordCreatedDate: '',
      },
    ],
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values
    //get data and rename it to courses (the object name)
    //get all discussion by the module id
    const blogTitle = this.props.match.params.blogTitle
    console.log(blogTitle)

    const { data: blog } = await fetchBlogByTitle(blogTitle)
    //const result = Object.entries(discussions);
    console.log(blog)
    //update the courses by setting the state
    this.setState({ blog })

    //get the other records
    const { data: latestBlogs } = await fetchLatestBlogs(3)
    console.log(latestBlogs)
    //update ui
    this.setState({ latestBlogs })

    //fetch the blog tags and categories

    const { data: blogTags } = await fetchAllBlogTags()
    this.setState({ blogTags })

    const { data: blogTagCategories } = await fetchAllBlogCategories()
    this.setState({ blogTagCategories })
  }
  render() {
    const { blog, latestBlogs, blogTags, blogTagCategories } = this.state

    return (
      <React.Fragment>
        <section className="page-title text-center style-two">
          <img alt="" className="pattern-layer" src={shape62} />
          <div className="auto-container">
            <div className="content-box">
              <div className="file-box">
                <i className="far fa-folder-open"></i>
                <p>{blog.blogCategory}</p>
              </div>
              <h2>{blog.blogTitle}</h2>
              <ul className="info-box clearfix">
                <li>
                  <i className="far fa-user"></i>
                  <span>Author: </span>

                  {blog.authorDetail.map((value, index) => (
                    <span key={index}>
                      {value.firstName} {value.lastName}
                    </span>
                  ))}
                </li>
                <li>
                  <i className="far fa-calendar-alt"></i>
                  <span>Posted On:</span>{' '}
                  <Moment format="D MMM YYYY" withTitle>
                    {blog.recordCreatedDate != null
                      ? blog.recordCreatedDate
                      : Date().toLocaleString()}
                  </Moment>
                </li>
                <li>
                  <i className="far fa-comment-alt"></i>
                  <span>Post Comments:</span> {blog.numberOfComments}
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="sidebar-page-container blog-details">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                <div className="blog-details-content">
                  <div className="inner-box">
                    <div className="text">
                      <h3>Overview</h3>
                      <p>{blog.blogOverview}</p>
                      {/* Section start */}
                      {blog.blogSections.map((sectionValue, index) => (
                        <BlogSection
                          sectionTitle={sectionValue.sectionTitle}
                          sectionMainImageUrl={sectionValue.sectionMainImageUrl}
                          sectionMainContent={sectionValue.sectionMainContent}
                          mediaUrls={sectionValue.mediaUrls}
                        />
                      ))}
                    </div>

                    {/* Section end */}
                  </div>
                  <div className="post-share-option">
                    <div className="post-tags">
                      <ul className="tags-list clearfix">
                        <li>
                          <i className="fas fa-tags"></i>
                          <h5>Tags: </h5>
                        </li>
                        {blog.blogTags.map((value, index) => (
                          <li key={index}>
                            {' '}
                            {value.tagName}
                            {index < blog.blogTags.length - 1 && (
                              <span>, </span>
                            )}
                          </li>
                        ))}
                        {/*<li>
                          <a href="blog-details.html">Analysis</a>,
                        </li>
                        <li>
                          <a href="blog-details.html">Consulting</a>,
                        </li>
                        <li>
                          <a href="blog-details.html">Data</a>,
                        </li>
                        <li>
                          <a href="blog-details.html">Engineering</a>
                        </li>*/}
                      </ul>
                    </div>
                    <div className="post-social">
                      <h5>Share this post with your friends</h5>
                      <ul className="social-list clearfix">
                        {/* TODO
                        <li>
                          <a href="blog-details.html">
                            <i className="fab fa-facebook-f"></i>Facebook
                          </a>
                        </li>
                        <li>
                          <a href="blog-details.html">
                            <i className="fab fa-twitter"></i>Twiter
                          </a>
                        </li>*/}
                        <li>
                          <a
                            href="https://www.linkedin.com/company/vengarre"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-linkedin-in"></i>Linkedin
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {blog.authorDetail.map((value, index) => (
                    <div className="author-box" key={index}>
                      <figure className="author-image">
                        <img
                          src={value.authorProfileImageUrl}
                          styles={{ height: '100px', width: '100px' }}
                          alt="Primary Author"
                        />
                      </figure>
                      <div className="inner">
                        <div className="author-info">
                          <h4>
                            {value.firstName} {value.lastName}
                          </h4>
                        </div>
                        <div className="text">
                          <p>
                            Managing editor and content provider at Vengarre
                            Consulting.
                          </p>
                        </div>
                        <div className="share">
                          <Link to="#" className="share-btn">
                            <i className="flaticon-share"></i>
                          </Link>
                          <ul className="social-links clearfix">
                            <li>
                              <Link to="#">
                                <i className="fab fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="fab fa-twitter"></i>
                              </Link>
                            </li>

                            <li>
                              <a
                                href="https://www.linkedin.com/company/vengarre"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/*Future Feature 
                  <div className="comments-area">
                    <div className="group-title">
                      <h3>Comments - 2</h3>
                    </div>
                    <div className="comment-box">
                      <div className="comment">
                        <figure className="author-thumb">
                          <img src="assets/images/news/comment-1.png" alt="" />
                        </figure>
                        <div className="comment-inner">
                          <div className="comment-info">
                            <h4>Steven Rich,</h4>
                            <span className="date">Dec 26, 2019</span>
                          </div>
                          <div className="text">
                            <p>
                              On the other hand, we denounce with righteous
                              indignation dislike men which toil and some great
                              pleasure.
                            </p>
                          </div>
                          <div className="replay-btn">
                            <a href="blog-details.html">Reply</a>
                          </div>
                        </div>
                      </div>
                      <div className="comment">
                        <figure className="author-thumb">
                          <img src="assets/images/news/comment-2.png" alt="" />
                        </figure>
                        <div className="comment-inner">
                          <div className="comment-info">
                            <h4>William Cobus,</h4>
                            <span className="date">Dec 25, 2019</span>
                          </div>
                          <div className="text">
                            <p>
                              Our power of choice is untrammelled and when
                              nothing prevents our being that able to do what we
                              like best is to be welcomed.
                            </p>
                          </div>
                          <div className="replay-btn">
                            <a href="blog-details.html">Reply</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  */}
                  {/* Future Feature 
                  <div className="comments-form-area">
                    <div className="group-title">
                      <h3>Leave a Reply</h3>
                    </div>
                    <form
                      method="post"
                      action="assets/inc/sendemail.php"
                      id="contact-form"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Your Comment..."
                          ></textarea>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="text"
                            name="username"
                            placeholder="Name*"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email*"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                          <button
                            className="theme-btn style-three"
                            type="submit"
                            name="submit-form"
                          >
                            Post Comments<span>+</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                {/* Add the side bar Here start*/}
                <SideBarComponent
                  latestBlogs={latestBlogs}
                  blogTags={blogTags}
                  blogTagCategories={blogTagCategories}
                />
                {/* Add the side bar Here end*/}
              </div>
            </div>
          </div>
        </section>
        {/* TODO Show Other Blogs Here that doesnt include the current*/}
        <div className="nav-btn-box style-two" hidden>
          <div className="auto-container">
            <div className="btn-inner clearfix">
              <div className="btn-left pull-left">
                <div className="prev-btn">
                  <h4>
                    <a href="portfolio-details.html">
                      <i className="fas fa-angle-double-left"></i>&nbsp;Prev
                      Post
                    </a>
                  </h4>
                </div>
                <div className="box">
                  <div className="post-date">
                    <p>16</p>
                    <span>Dec</span>
                  </div>
                  <div className="file-box">
                    <i className="far fa-folder-open"></i>&nbsp;&nbsp;
                    <p>Data Strategy</p>
                  </div>
                  <h4>Naxly as the Winners in Global Agency Awards</h4>
                </div>
              </div>
              <div className="btn-right pull-right text-right">
                <div className="prev-btn">
                  <h4>
                    <a href="portfolio-details.html">
                      Next Post&nbsp;
                      <i className="fas fa-angle-double-right"></i>
                    </a>
                  </h4>
                </div>
                <div className="box">
                  <div className="post-date">
                    <p>05</p>
                    <span>Jan</span>
                  </div>
                  <div className="file-box">
                    <p>Data Engineering</p>&nbsp;&nbsp;
                    <i className="far fa-folder-open"></i>
                  </div>
                  <h4>Naxly Named as a Global Leader in Big Data</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default BlogDetail
