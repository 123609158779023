import React, { Component } from 'react'
import { postNewsLetterSubscriber } from '../../../services/blogs/newsletter/newsletterService'
import { toast } from 'react-toastify'
import Joi from 'joi-browser'
import TextInputLabel from '../../reuseable/form/textinputlabel'
import SubmitButton from '../../reuseable/buttons/submitbutton'
import SubscribeButton from '../../reuseable/buttons/subscribebutton'
class SubscriberForm extends Component {
  state = {
    data: {
      emailAddress: '',
    },
    errors: {},
  }

  //Joi validation schema
  schema = {
    emailAddress: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ['com', 'edu', 'net'] },
      })
      .required()
      .label('Email Address'), //use joi in node as well
  }
  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null

    const errors = {}

    for (let item of error.details) {
      errors[item.path[0]] = item.message
      toast.error('An unexpected error occurred.\n' + item.message)
    }
    return errors
  }
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema)

    return error ? error.details[0].message : null
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = this.validate()
    console.log(errors)

    this.setState({ errors: errors || {} })
    if (errors) return

    this.doSubmit(e)
  }

  handleChange = ({ currentTarget: input }) => {
    //input validation
    const errors = { ...this.state.errors }

    const errorMessage = this.validateProperty(input)

    if (errorMessage) errors[input.name] = errorMessage
    else delete errors[input.name]
    //data seccount (model)
    const data = { ...this.state.data }

    data[input.name] = input.value
    this.setState({ data, errors })
  }

  doSubmit = async (e) => {
    //login function
    try {
      const { data } = this.state
      const newletterPayLoad = {
        emailAddress: data.emailAddress,
      }
      console.log('Data to send to the server')
      //console.log(newletterPayLoad)
      //Send to server
      const { data: result } = await postNewsLetterSubscriber(newletterPayLoad)
      console.log(result)
      toast.success(result)
      //get the current location
      //const { state } = this.props.location

      //force a reload of the page
      // window.location = state ? state.from.pathname : '/dashboard'
      //toast.success(
      // 'Your Request has been successfully sent. Thank you, we will get back to you soon.',
      // )
      //Successful so clear the form
      e.target.reset()
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.emailAddress = ex.response.data
        toast.error('An unexpected error occurred.\n' + ex.response.data)
        //Update the UI
        this.setState({ errors })
      }
    }
  }
  render() {
    const { data, errors } = this.state
    return (
      <React.Fragment>
        <div className="sidebar-widget sidebar-subscribe">
          <div className="widget-title">
            <h3>Subscribe Us</h3>
          </div>
          <div className="widget-content">
            <p>
              Subscribe us and get latest blogs, product releases and updates to
              your inbox directly.
            </p>
            <form onSubmit={this.handleSubmit} className="subscribe-form">
              <div className="form-group">
                <TextInputLabel
                  name="emailAddress"
                  value={this.emailAddress}
                  label=""
                  labelClass="far fa-envelope"
                  type="email"
                  placeholder="Email Address here"
                  error={errors[this.emailAddress]}
                  onChange={this.handleChange}
                  iconClassName="far fa-envelope"
                />

                <SubscribeButton
                  type="submit"
                  name="submit-form"
                  buttonTitle="Subscribe"
                  iconClassName="fas fa-rss"
                />
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SubscriberForm
