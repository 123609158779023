import React from 'react'
import { Link } from 'react-router-dom'
import service8 from '../../../styletheme/images/service/service-8.png'
import service7 from '../../../styletheme/images/service/service-7.png'
import custom from '../../../styletheme/css/custom.css'
import MinimalBlogCard from '../listrows/minimalblogcard'
import { postNewsLetterSubscriber } from '../../../services/blogs/newsletter/newsletterService'
import SubscriberForm from './subscriberform'

const SideBarComponent = ({
  latestBlogs,
  blogTags,
  blogTagCategories,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="sidebar">
        {/* Need to add the side bar search soon, so it searches the list of where it is being used or 
          I could add the search on the specific screen*/}
        <div className="sidebar-widget sidebar-search" hidden>
          <div className="widget-title">
            <h3>Search</h3>
          </div>
          <div className="search-box">
            <form action="blog-details.html" method="post">
              <div className="form-group">
                <input
                  type="search"
                  name="search-field"
                  placeholder="Keyword..."
                  required=""
                />
                <button type="submit" className="theme-btn style-one">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sidebar-widget sidebar-categories">
          <div className="widget-title">
            <h3>Categories</h3>
          </div>
          <div className="widget-content">
            <ul className="categories-list clearfix">
              {/*Eventually add the filter feature so when its clicked it will go to blogs and prefiltered */}
              {blogTagCategories.map((value, index) => (
                <li key={index}>
                  <Link to="/blogs">{value.tagName}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="sidebar-widget sidebar-post">
          <div className="widget-title">
            <h3>Recent Blogs</h3>
          </div>
          <div className="post-inner">
            {latestBlogs.map((value, index) => (
              <div key={index}>
                <MinimalBlogCard
                  blogTitle={value.blogTitle}
                  blogCategory={value.blogCategory}
                  blogPromoImageUrl={value.blogPromoImageUrl}
                  recordCreatedDate={value.recordCreatedDate}
                  path={`/blogdetail/${value.blogTitle}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/*Subscriber Form start */}
        <SubscriberForm />
        {/*Subscriber Form end */}
        <div className="sidebar-widget sidebar-tags">
          <div className="widget-title">
            <h3>Tag Cloud</h3>
          </div>
          <div className="widget-content">
            <ul className="tags-list clearfix">
              {blogTags.map((value, index) => (
                <li key={index}>
                  <Link to="/blogs">
                    {index % 2 === 0 && <span>{value.tagName}</span>}
                    {index % 2 !== 0 && (
                      <React.Fragment>{value.tagName}</React.Fragment>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideBarComponent
