import React, { Component, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../../styletheme/logo/VengarreConsulting.png'
import fullLogo from '../../../styletheme/logo/VengarreConsultingFullLogo.png'
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'
//https://www.digitalocean.com/community/tutorials/react-react-burger-menu-sidebar
const NavBar = (props) => {
  //render() {
  const COLORS = {
    primaryDark: '#115b4c',
    primaryLight: '#B6EDC8',
  }

  const MenuLabel = styled.label`
    background-color: ${COLORS.primaryDark};
    position: fixed;
    top: 3rem;
    right: 1rem;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
    text-align: center;
  `

  const NavBackground = styled.div`
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      ${COLORS.primaryDark},
      ${COLORS.primaryLight}
    );
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: ${(props) => (props.clicked ? 'scale(80)' : 'scale(0)')};
    transition: transform 0.8s;
  `

  const Icon = styled.span`
    position: relative;
    background-color: ${(props) => (props.clicked ? 'transparent' : 'white')};
    width: 3rem;
    height: 2px;
    display: inline-block;
    margin-top: 3.5rem;
    transition: all 0.3s;
    &::before,
    &::after {
      content: '';
      background-color: white;
      width: 3rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::before {
      top: ${(props) => (props.clicked ? '0' : '-0.8rem')};
      transform: ${(props) => (props.clicked ? 'rotate(135deg)' : 'rotate(0)')};
    }
    &::after {
      top: ${(props) => (props.clicked ? '0' : '0.8rem')};
      transform: ${(props) =>
        props.clicked ? 'rotate(-135deg)' : 'rotate(0)'};
    }
    ${MenuLabel}:hover &::before {
      top: ${(props) => (props.clicked ? '0' : '-1rem')};
    }
    ${MenuLabel}:hover &::after {
      top: ${(props) => (props.clicked ? '0' : '1rem')};
    }
  `

  const Navigation = styled.nav`
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 600;
    width: ${(props) => (props.clicked ? '100%' : '0')};
    opacity: ${(props) => (props.clicked ? '1' : '0')};
    transition: width 0.8s, opacity 0.8s;
  `

  const List = styled.ul`
    position: absolute;
    list-style: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  `
  const ItemLink = styled(NavLink)`
    display: inline-block;
    font-size: 3rem;
    font-weight: 300;
    text-decoration: none;
    color: ${COLORS.primaryLight};
    padding: 1rem 2rem;
    background-image: linear-gradient(
      120deg,
      transparent 0%,
      transparent 50%,
      #fff 50%
    );
    background-size: 240%;
    transition: all 0.4s;
    &:hover,
    &:active {
      background-position: 100%;
      color: ${COLORS.primaryDark};
      transform: translateX(1rem);
    }
  `

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  return (
    <React.Fragment>
      {/*preloader */}
      {/*  <div className="preloader">
                    <div id="handle-preloader" className="handle-preloader">
                        <div className="animation-preloader">
                            <div className="spinner"></div>
                            <div className="txt-loading">
                                <span data-text-preloader="N" className="letters-loading">
                                    N
                                </span>
                                <span data-text-preloader="A" className="letters-loading">
                                    A
                                </span>
                                <span data-text-preloader="X" className="letters-loading">
                                    X
                                </span>
                                <span data-text-preloader="L" className="letters-loading">
                                    L
                                </span>
                                <span data-text-preloader="Y" className="letters-loading">
                                    Y
                                </span>
                            </div>
                        </div>  
                    </div>
                </div>
               preloader end */}

      {/*search-popup */}
      <div id="search-popup" className="search-popup">
        <div className="close-search">
          <span>Close</span>
        </div>
        <div className="popup-inner">
          <div className="overlay-layer"></div>
          <div className="search-form">
            {/*<form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <fieldset>
                  <input
                    type="search"
                    className="form-control"
                    name="search-input"
                    placeholder="Search Here"
                    required
                  />
                  <button className="theme-btn style-four">Search Now!</button>
                </fieldset>
              </div>
            </form>
            <h3>Recent Search Keywords</h3>
            <ul className="recent-searches">
              <li>
                <a href="index.html">Finance</a>
              </li>
              <li>
                <a href="index.html">Idea</a>
              </li>
              <li>
                <a href="index.html">Service</a>
              </li>
              <li>
                <a href="index.html">Growth</a>
              </li>
              <li>
                <a href="index.html">Plan</a>
              </li>
            </ul>*/}
          </div>
        </div>
      </div>
      {/*search-popup end */}

      {/*main header */}
      <header className="main-header style-one home-1">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-12 col-sm-12 logo-column">
              <div className="logo-box style-two" style={{ border: '0px' }}>
                <figure className="logo">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Vengarre Consulting logo"
                      style={{
                        height: '65px',
                        width: '150px',
                        border: '0 !important',
                      }}
                    />
                    <h4 style={{ color: 'wheat' }}>Vengarre Consulting</h4>
                  </Link>
                </figure>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 outer-column">
              <div className="outer-box">
                <div className="header-top clearfix">
                  <div className="top-right pull-right clearfix">
                    <div className="support">
                      <span>Start your project today</span>
                    </div>
                    <ul className="social-links clearfix">
                      <li>
                        <Link to="/">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-google-plus-g"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-skype"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="header-upper clearfix">
                  <div className="menu-area pull-right clearfix">
                    {/*Mobile Navigation Toggler*/}
                    <div className="mobile-nav-toggler">
                      <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                        <Icon clicked={click}>&nbsp;</Icon>
                      </MenuLabel>
                      <NavBackground clicked={click}>&nbsp;</NavBackground>
                    </div>

                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div
                        className="collapse navbar-collapse show clearfix"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation clearfix">
                          <li className="current ">
                            <NavLink className="nav-link" to="/">
                              Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="nav-link" to="/about">
                              About
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="nav-link" to="/projects">
                              Projects
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="nav-link" to="/services">
                              Services
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="nav-link" to="/blogs">
                              Blogs
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="theme-btn style-one"
                              to="/requestquote"
                              style={{ padding: '8px' }}
                            >
                              Request Quote
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    <div className="btn-box">
                      <Navigation clicked={click}>
                        <List>
                          <li>
                            <ItemLink onClick={handleClick} to="/">
                              Home
                            </ItemLink>
                          </li>
                          <li>
                            <ItemLink onClick={handleClick} to="/about">
                              About
                            </ItemLink>
                          </li>
                          <li>
                            <ItemLink onClick={handleClick} to="/projects">
                              Projects
                            </ItemLink>
                          </li>
                          <li>
                            <ItemLink onClick={handleClick} to="/services">
                              Services
                            </ItemLink>
                          </li>
                          <li>
                            <ItemLink onClick={handleClick} to="/blogs">
                              Blogs
                            </ItemLink>
                          </li>
                          <li>
                            <ItemLink onClick={handleClick} to="/requestquote">
                              Request Quote
                            </ItemLink>
                          </li>
                        </List>
                      </Navigation>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/*sticky Header*/}
        <div className="sticky-header">
          <div className="auto-container clearfix">
            <figure className="logo-box">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </figure>
            <div className="menu-area">
              <nav className="main-menu clearfix">
                {/*Keep This Empty / Menu will come through Javascript*/}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/*main-header end */}

      {/*{/*Mobile Menu  use this https://www.npmjs.com/package/react-burger-menu*/}
      <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn">
          <i className="fas fa-times"></i>
        </div>

        <nav className="menu-box">
          <div className="nav-logo">
            <Link to="/">
              <img src={logo} alt="" title="" />
            </Link>
          </div>
          <div className="menu-outer">
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>Bangor Maine, USA</li>

              <li>
                <a href="mailto:vengarre@vengarre.com">vengarre@vengarre.com</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <Link to="/">
                  <span className="fab fa-twitter"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-facebook-square"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-pinterest-p"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-instagram"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-youtube"></span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  )
  //}
}

export default NavBar
