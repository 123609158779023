import React, { Component } from 'react'
import shape62 from '../../../styletheme/images/shape/shape-62.png'
import decor1 from '../../../styletheme/images/icons/decor-1.png'
import shape48 from '../../../styletheme/images/shape/shape-48.png'
import Joi from 'joi-browser'
import TextInputLabel from '../../reuseable/form/textinputlabel'
import TextAreaInputLabel from '../../reuseable/form/textareainputlabel'
import SubmitButton from '../../reuseable/buttons/submitbutton'
import { toast } from 'react-toastify'
import { postProjectRequest } from '../../../services/contact/projectRequest'
import projectRequestImg from '../../../styletheme/images/custom/contact.png'

class RequestQuote extends Component {
  state = {
    data: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      subject: '',
      messageDetail: '',
    },
    errors: {},
  }

  //Joi validation schema
  schema = {
    emailAddress: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ['com', 'edu', 'net'] },
      })
      .required()
      .label('Email Address'), //use joi in node as well
    firstName: Joi.string().required().label('First Name'),
    lastName: Joi.string().required().label('Last Name'),
    subject: Joi.string().required().label('Subject'),
    messageDetail: Joi.string().required().label('Message'),
  }
  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null

    const errors = {}

    for (let item of error.details) {
      errors[item.path[0]] = item.message
      toast.error('An unexpected error occurred.\n' + item.message)
    }
    return errors
  }
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema)

    return error ? error.details[0].message : null
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = this.validate()
    console.log(errors)

    this.setState({ errors: errors || {} })
    if (errors) return

    this.doSubmit(e)
  }

  handleChange = ({ currentTarget: input }) => {
    //input validation
    const errors = { ...this.state.errors }

    const errorMessage = this.validateProperty(input)

    if (errorMessage) errors[input.name] = errorMessage
    else delete errors[input.name]
    //data seccount (model)
    const data = { ...this.state.data }

    data[input.name] = input.value
    this.setState({ data, errors })
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

  }

  doSubmit = async (e) => {
    //login function
    try {
      const { data } = this.state
      const contactPayLoad = {
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        subject: data.subject,
        messageDetail: data.messageDetail,
      }
      console.log('Data to send to the server')
      console.log(contactPayLoad)
      //Send to server
      await postProjectRequest(contactPayLoad)

      //get the current location
      const { state } = this.props.location

      //force a reload of the page
      // window.location = state ? state.from.pathname : '/dashboard'
      toast.success(
        'Your Request has been successfully sent. Thank you, we will get back to you soon.',
      )
      //Successful so clear the form
      e.target.reset()
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.emailAddress = ex.response.data
        toast.error('An unexpected error occurred.\n' + ex.response.data)
        //Update the UI
        this.setState({ errors })
      }
    }
  }
  render() {
    const { data, errors } = this.state
    return (
      <React.Fragment>
        <div className="boxed_wrapper">
          <section className="page-title text-center style-two">
            <img alt="" className="pattern-layer" src={shape62} />
            <div className="auto-container">
              <div className="content-box">
                <h1>Request A Project Quote</h1>
              </div>
            </div>
          </section>
        </div>
        {/*  <!-- info-section -->*/}
        <section className="info-section alternate-2">
          <div className="auto-container">
            <div className="inner-container">
              <div className="top-inner mb-55">
                <div className="sec-title text-center">
                  <p>Contact Information</p>
                  <h2>How does this form work?</h2>

                  <img alt="" className="decor" src={decor1} />
                </div>
                <div className="text text-center">
                  <p>
                    We will ask you a few questions that you will fill out below
                    so we will be able to contact you to guide you through
                    turning <br />
                    your idea into an efficient solution within your budget.
                    <br />
                  </p>
                </div>
              </div>
              <div className="info-inner">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                    <div className="info-box">
                      <div className="hidden-icon">
                        <i className="flaticon-pin"></i>
                      </div>
                      <div className="box">
                        <div className="icon-box">
                          <i className="flaticon-pin"></i>
                        </div>
                        <h4>Location</h4>
                        <span>remotely as well</span>
                      </div>
                      <div className="text">
                        <p>
                          Bangor, Maine
                          <br />
                          USA
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 info-column">
                    <div className="info-box">
                      <div className="hidden-icon">
                        <i className="flaticon-music"></i>
                      </div>
                      <div className="box">
                        <div className="icon-box">
                          <i className="flaticon-music"></i>
                        </div>
                        <h4>We are here for you</h4>
                        <span>Talk with our experts</span>
                      </div>
                      <div className="text">
                        <p>We are available via the form below:</p>
                        <p>Mon - Fri: 09:00 AM to 5:00 PM</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 info-column">
                    <div className="info-box">
                      <div className="hidden-icon">
                        <i className="flaticon-gmail"></i>
                      </div>
                      <div className="box">
                        <div className="icon-box">
                          <i className="flaticon-gmail"></i>
                        </div>
                        <h4>Send a Mail</h4>
                        <span>Dont’s hesitate to mail</span>
                      </div>
                      <div className="text">
                        <p>
                          <a href="mailto:vengarre@vengarre.com">
                            vengarre@vengarre.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- info-section end -->*/}

        {/*<!-- contact-section -->*/}
        <section className="contact-section alternate-2">
          <img alt="" className="pattern-layer" src={shape48} />
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_09">
                  <div className="content-box">
                    <form
                      onSubmit={this.handleSubmit}
                      id="contact-form"
                      className="default-form"
                    >
                      <TextInputLabel
                        name="firstName"
                        value={this.firstName}
                        label="First Name"
                        labelClass="far fa-user"
                        type="text"
                        placeholder="Enter first name here"
                        error={errors[this.firstName]}
                        onChange={this.handleChange}
                        iconClassName="far fa-user"
                      />

                      <TextInputLabel
                        name="lastName"
                        value={this.lastName}
                        label="Last Name"
                        labelClass="far fa-user"
                        type="text"
                        placeholder="Enter last name here"
                        error={errors[this.lastName]}
                        onChange={this.handleChange}
                        iconClassName="far fa-user"
                      />

                      <TextInputLabel
                        name="emailAddress"
                        value={this.emailAddress}
                        label="Email Address"
                        labelClass="far fa-envelope"
                        type="email"
                        placeholder="Enter email address here"
                        error={errors[this.emailAddress]}
                        onChange={this.handleChange}
                        iconClassName="far fa-envelope"
                      />

                      <TextInputLabel
                        name="subject"
                        value={this.subject}
                        label="Subject"
                        labelClass="far fa-user"
                        type="text"
                        placeholder="Enter subject here"
                        error={errors[this.subject]}
                        onChange={this.handleChange}
                        iconClassName="far fa-user"
                      />

                      <TextAreaInputLabel
                        name="messageDetail"
                        value={this.messageDetail}
                        label="Message"
                        labelClass="fas fa-text-height"
                        placeholder="Enter your message goes here"
                        error={errors[this.messageDetail]}
                        onChange={this.handleChange}
                        iconClassName="fas fa-text-height"
                      />

                      <SubmitButton
                        className="theme-btn style-one"
                        type="submit"
                        name="submit-form"
                        label="Send Your Message"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div
                  className="image-box wow slideInRight"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <figure className="image js-tilt">
                    <img alt="" src={projectRequestImg} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- contact-section end -->*/}
      </React.Fragment>
    )
  }
}

export default RequestQuote
