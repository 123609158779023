import React from 'react'
import { Link } from 'react-router-dom'
import news6 from '../../../styletheme/images/news/news-6.jpg'
import Moment from 'react-moment'

const BlogCard = ({
  blogId,
  blogPromoImageUrl,
  blogTitle,
  blogCategory,
  blogOverview,
  recordCreatedDate,
  path,
  ...rest
}) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 news-block">
      <div
        className="news-block-one wow fadeInUp"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <figure className="image-box">
            <img src={blogPromoImageUrl} alt="" />
            <Link className="lightbox-image" data-fancybox="gallery" to={path}>
              <i className="flaticon-zoom"></i>
            </Link>
          </figure>
          <div className="lower-content">
            <div className="file-box">
              <i className="far fa-folder-open"></i>
              <p>{blogCategory}</p>
            </div>
            <div className="title-box">
              <div className="post-date">
                {/*<p>02</p>
                <span>Jan</span>*/}
                <span>
                  <b>
                    <Moment format="D MMM YYYY" withTitle>
                      {recordCreatedDate != null
                        ? recordCreatedDate
                        : Date().toLocaleString()}
                    </Moment>
                  </b>
                </span>
              </div>
              <h4>
                <Link to={path}>{blogTitle}</Link>
              </h4>
            </div>
            <div className="text">
              <p>{blogOverview}</p>
            </div>
            <div className="link">
              <Link to={path} className="btn-style-four">
                Read More<span>+</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
